
﻿import {
    Component,
    ComponentRef,
    ElementRef,
    NgModuleRef,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewContainerRef,
    createNgModuleRef
  } from '@angular/core';
  import {
    IModalDialog,
    IModalDialogButton,
    IModalDialogOptions,
    IModalDialogSettings,
    ModalDialogOnAction
  } from './modal.interface';
  import { from, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { resolve } from 'dns';
  
  /**
   * Modal dialog component
   *  (click)="this.close()"
   */
  @Component({
    selector: 'modal-dialog',
    styles: [],
    template: `
      <div class="screen-overlay" [class.visible]="animate" *ngIf="showOverlay"></div> 
      <div [ngClass]="isSnackbar ? 'snackbar '+isSnackbar : 'dialog '+extraClass" [class.visible]="animate" #dialog>
            
            <svg *ngIf="isSnackbar == 'success'" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
            <svg *ngIf="isSnackbar == 'normal'" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M 26 16 l 0 15 M 26 33 l 0 3"/></svg>
            <svg *ngIf="isSnackbar == 'error'" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M 16.1 16.1 l 20.1 20.1 M 36.1 16 l -20.1 20.1"/></svg>

            <div class="dialog-content" *ngIf="content" [innerHtml]="content"></div>
            <ng-container #modalDialogBody></ng-container>
            <div class="dialog-buttons" *ngIf="actionButtons && actionButtons.length">
              <button *ngFor="let button of actionButtons" (click)="doAction(button.onAction)"
                      [ngClass]="button.buttonClass || settings.buttonClass">{{button.text}}
              </button>
            </div>
      </div>
    `
  })
  export class ModalDialogComponent implements IModalDialog, OnDestroy, OnInit {
    @ViewChild('modalDialogBody', { read: ViewContainerRef, static: true }) public dynamicComponentTarget: ViewContainerRef;
    @ViewChild('dialog') private dialogElement: ElementRef;
    public reference: ComponentRef<IModalDialog>;
  
    /** Modal dialog style settings */
    public settings: IModalDialogSettings = {
      modalDialogClass: 'modal-dialog modal-dialog-centered',
      contentClass: 'modal-content',
      headerClass: 'modal-header',
      headerTitleClass: 'modal-title',
      closeButtonClass: 'close glyphicon glyphicon-remove',
      closeButtonTitle: 'CLOSE',
      bodyClass: 'modal-body',
      footerClass: 'modal-footer',
      alertClass: 'modal-shake',
      alertDuration: 250,
      notifyWithAlert: true,
      buttonClass: 'flat-button primary'
    };
    public actionButtons: IModalDialogButton[];
    public title: string;
    public content: string;
    public code = false;
    public onClose: () => Promise<any> | Observable<any> | boolean;
  
    // public showAlert = false;
    public animate = true;
    public showOverlay = true;
    public isSnackbar = '';
    public extraClass = '';
    public timerSub: Subscription;
  
    private _inProgress = false;
    // private _alertTimeout: number;
    private _childInstance: any;
    private _closeDialog$: Subject<any>;
  
    /**
     * CTOR
     * @param _element
     * @param componentFactoryResolver
     */
    constructor(protected _element: ElementRef, protected renderer: Renderer2) {
    }
  
    /**
     * Initialize dialog with reference to instance and options
     * @param reference
     * @param options
     */
    async dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>> = {}) {
      this.reference = reference;
  
      // inject component
      if (options.childComponent) {

        let cmp: any;
        let type = 'injected';
        switch (options.childComponent) {
          case 'LazyUserConfirmComponent':
            type = 'lazy';
            const { LazyUserConfirmComponent } = await import('../../lazy/lazy-user-confirm.component');
            cmp = LazyUserConfirmComponent;
            break;
          case 'LazyCookieConfirmComponent':
            type = 'lazy';
            this.extraClass = 'narrow';
            const { LazyCookieConfirmComponent } = await import('../../lazy/lazy-cookie-confirm.component');
            cmp = LazyCookieConfirmComponent;
            break;
          case 'LazyDiscountCodeComponent':
            type = 'lazy';
            const { LazyDiscountCodeComponent } = await import('../../lazy/lazy-discount-code.component');
            cmp = LazyDiscountCodeComponent;
            this.code = true;
            break;
          case 'LazyClassicaComponent':
            type = 'lazy';
            const { LazyClassicaComponent } = await import('../../lazy/lazy-classica.component');
            cmp = LazyClassicaComponent;
            break;
          //ve
          case 'LazyUploadComponent':
            type = 'lazy';
            const { LazyUploadComponent } = await import('../../lazy/lazy-upload.component');
            cmp = LazyUploadComponent;
            break;
          case 'LazyEditorComponent':
            type = 'lazy';
            const { LazyEditorComponent } = await import('../../lazy/lazy-editor.component');
            cmp = LazyEditorComponent;
            break;
        }
        
        if (!cmp) {
          cmp = options.childComponent;
        }

        if (cmp) {
          let componentRef = this.dynamicComponentTarget.createComponent(cmp);
          // let componentRef = this.dynamicComponentTarget.createComponent(options.childComponent) as ComponentRef<IModalDialog>;
          this._childInstance = componentRef.instance as IModalDialog;
          if (options.closeDialogSubject) {
            this._closeDialog$ = options.closeDialogSubject;
          } else {
            this._closeDialog$ = new Subject<void>();
            options.closeDialogSubject = this._closeDialog$;
          }
          this._closeDialog$.subscribe(() => {
            this._finalizeAndDestroy();
          });
          this._childInstance['dialogInit'](componentRef, options);
          document.activeElement != null ?
            (document.activeElement as HTMLElement).blur() :
            (document.body as HTMLElement).blur();
        }
      }
      // set options
      this._setOptions(options);
    }
 
    ngOnInit() {
      // a trick to defer css animations
      this.animate = true;

      if (this.isSnackbar != '') {
        const source = timer(5000);
        this.timerSub = source.subscribe(val => {
          this._closeIfSuccessful(this.onClose);
        });
      }
    }
  
    /**
     * Cleanup on destroy
     */
    ngOnDestroy() {
      // run animations
      this.animate = false;
  
      // cleanup listeners
      if (this.timerSub) {
        this.timerSub.unsubscribe();
      }
  
      if (this._closeDialog$) {
        this._closeDialog$.unsubscribe();
      }
    }
  
    /**
     * Run action defined on action button
     * @param action
     */
    doAction(action?: ModalDialogOnAction) {
      // disable multi clicks
      if (this._inProgress) {
        return;
      }
      this._inProgress = true;
      this._closeIfSuccessful(action);
    }
  
    /**
     * Method to run on close
     * if action buttons are defined, it will not close
     */
    public close() {
      if (this._inProgress) {
        return;
      }
      if (this.actionButtons && this.actionButtons.length) {
        return;
      }
      this._inProgress = true;
  
      if (this.onClose) {
        this._closeIfSuccessful(this.onClose);
        return;
      }
      this._finalizeAndDestroy();
    }
  
    /**
     * Pass options from dialog setup to component
     * @param  {IModalDialogOptions} options?
     */
    private _setOptions(options: Partial<IModalDialogOptions<any>>) {
  
      if (options.onClose && options.actionButtons && options.actionButtons.length) {
        throw new Error(`OnClose callback and ActionButtons are not allowed to be defined on the same dialog.`);
      }
      // set reference
      this.title = (options && options.title) || '';
      this.content = (options && options.content) || '';
      this.isSnackbar = (options && options.isSnackbar) || '';
      if (this.isSnackbar == '') {
        this.renderer.addClass(document.body, 'disabled-modal');
      } else {
        this.showOverlay = false;
      }

      if (options.closeDialogSubject) {
        this._closeDialog$ = options.closeDialogSubject;
      }
      this.onClose = (options && options.onClose) || null;
      this.actionButtons = (this._childInstance && this._childInstance['actionButtons']) ||
        (options && options.actionButtons) || null;
      if (options && options.settings) {
        Object.assign(this.settings, options.settings);
      }
    }
  
    /**
     * Close if successful
     * @param callback
     */
    private _closeIfSuccessful(callback: ModalDialogOnAction) {
      if (!callback) {
        return this._finalizeAndDestroy();
      }
  
      let response = callback();
      if (typeof response === 'boolean') {
        if (response) {
          return this._finalizeAndDestroy();
        }
        return true;// this._triggerAlert();
      }
      if (this.isPromise(response)) {
        response = from(response);
      }
      if (this.isObservable(response)) {

        response.subscribe({
          next: (v) => {this._finalizeAndDestroy()},
          // error: (e) => this._triggerAlert(),
        });


      } else {
        this._inProgress = false;
      }
    }
  
    private _finalizeAndDestroy() {
      // if (this._closeDialog$ && this._childInstance) {
      //   this._closeDialog$.next(this._childInstance.data);
      // }
      this.renderer.removeClass(document.body, 'disabled-modal');
      this._inProgress = false;
      this.reference.destroy();
    }
  
    private isPromise<T>(value: any | Promise<T>): value is Promise<T> {
      return value && typeof (<any>value).subscribe !== 'function' && typeof (value as any).then === 'function';
    }
  
    private isObservable<T>(value: any | Observable<T>): value is Observable<T> {
      return value && typeof (<any>value).subscribe === 'function';
    }
  }