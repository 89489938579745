import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, Inject, PLATFORM_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Discount } from '../cart/cart.model';
import { utils } from './utils';

@Component({
  selector: 'app-price',
  templateUrl: 'price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PriceComponent implements OnChanges {

  @Input() public id: number;
  @Input() public price: number;
  @Input() public price_promo: number;
  @Input() public tax: number;
  @Input() public currency: string;
  @Input() public cart = false;
  @Input() public quantity: number;
  @Input() public discounts: Discount[];
  @Input() public category: any = [];
  @Input() public manufacturer: any;
  @Input() public series: string;
  @Input() public change: number;
  @Input() public code = '';
  @Input() public type = 'netto';
  @Input() public history = 0;
  @Input() public discountSelected: Discount;
  public old_price = 0;
  public new_price = 0;
  public new_price_withcode = 0;
  public discounted = false;
  public discount_value = 0;
  public userId = null;
  public userGroups = null;
  public priceCurrency = 'PLN';


  constructor(@Inject(PLATFORM_ID) private platformId: Object,  @Inject(DEFAULT_CURRENCY_CODE) private currencyDefault: string, private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.old_price = 0;
    this.new_price = 0;
    this.priceCurrency = this.currencyDefault;
    if (this.currency) {
      this.priceCurrency = this.currency;
    }
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('user_id')) {
        this.userId = +localStorage.getItem('user_id');
        if (localStorage.getItem('user_groups')) {
        this.userGroups = localStorage.getItem('user_groups').split(',').map( Number );
        }
      }
      
    }
  
    this.discounted = false;
    if (this.type == 'netto'){
      this.tax = 0;
    }
    const tax = this.price * (this.tax / 100);
    this.old_price = Math.round((+this.price + +tax) * 100) / 100;
    if (this.price_promo > 0){
      this.new_price  = Math.round((+this.price_promo + +tax) * 100) / 100;
      this.discounted = true;
    }

    if (this.id > -1) {
      // if (this.discounts) {
      //   this.discountSelected = this.discounts.find(discount => +discount.visible == 5);
      // }
    }
    
    if (this.discountSelected) {
      this.new_price_withcode = Math.round(((this.new_price ? this.new_price : this.old_price) - (this.old_price*(this.discountSelected.value/100))) * 100) / 100
    } 

    if (!this.discountSelected && this.discounted) {
      this.discountSelected = new Discount();
      this.discountSelected.value = 100 - this.new_price / this.old_price * 100; 
      this.new_price_withcode = this.new_price;
    }

    if ((this.new_price < this.history) && !this.cart && (!this.history || this.history == 0)) {
      this.history = this.new_price;
    }

  }

}
