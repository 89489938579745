<div class="scrollable {{ type }}" #scrollable>
    <ng-content></ng-content>
</div>
<div *ngIf="gallery" class="scrollable-nav">
    <!-- <span class="scrollable-nav-point" *ngFor="let slide of gallery; let i = index" (click)="scrollTo(i)">
        <img [ngSrc]="slide.filename"  [loaderParams]="{module: 'products', 'dir': slide.dir}" width="40" height="40">
    </span> -->
    <button #button class="scroll-button prev" [disabled]="(currentX | async) < (limitStart | async)" (click)="scrollLeft()" alt="previous"><svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg></button>
    <button #button class="scroll-button next" [disabled]="(currentX | async) >= (limitEnd | async)" (click)="scrollRight()" alt="next"><svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg></button>
</div>
<div class="scrollbar"><div #scrollbar class="track"><span></span></div></div>