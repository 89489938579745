import { Injectable, Inject, LOCALE_ID, PLATFORM_ID, EventEmitter, Injector } from '@angular/core';
import { isPlatformBrowser, DOCUMENT, isPlatformServer } from '@angular/common';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { pluck, shareReplay, publishReplay, refCount } from 'rxjs/operators';
import { Page, Setup, Popup, Downloads, Review, Slides, Settings } from './pages.model';
import { environment } from './../../../environments/environment';
import { USER_DATA } from 'src/app/tokens';

/**
 * This class provides the NameList service with methods to read names and add names.
 */
@Injectable()
export class NavigationService {

  public navigation = new BehaviorSubject({
    menu: [],
    footer: [],
    invisible: [],
    settings: new Settings,
    discounts: [],
    payments: [],
    carriers: []
  });
  public currentPage = new BehaviorSubject({});
  public breadcrumbs = new BehaviorSubject([]);
  public pageSettings = new BehaviorSubject({});
  public code = new BehaviorSubject(null);
  public mobile = new BehaviorSubject('mobile');
  public clickId = new BehaviorSubject(null);
  public ipAddress = null;
  public triggerChat = new EventEmitter<boolean>();

  constructor(private injector: Injector, @Inject(PLATFORM_ID) private platformId: Object, @Inject(LOCALE_ID) public localeId: string, @Inject(DOCUMENT) private doc, private httpClient: HttpClient, private titleService: Title, private metaService: Meta) {
    if (isPlatformServer(this.platformId)) {
      const user_data: any = injector.get(USER_DATA) || null;
      if (user_data.device == undefined) {
        this.mobile.next('desktop');
      } else {
        this.mobile.next('mobile');
      }
    }  
  }

  public items(): Observable<Page[]> {
    return this.httpClient.get<Page[]>(environment.api + '/menu');
  }

  public item(slug: string): Observable<Page> {
    return this.httpClient.get<Page>(environment.api + '/page/' + slug, {headers: {'Content-Language': this.localeId}} );
  }

  public settings(lang: string): Observable<Setup> {
    return this.httpClient.get<Setup>(environment.api + '/settings/' + lang).pipe(publishReplay(1), refCount());
  }

  public popup(query: any): Observable<Popup> {
    const params = new HttpParams({fromObject: query});
    return this.httpClient.get<Popup>(environment.api + '/popup', { headers: {'Content-Language': this.localeId}, params: params });
  }

  public downloads(): Observable<Downloads> {
    return this.httpClient.get<Downloads>(environment.api + '/downloads');
  }

  public email(data: any): Observable<any> {
    return this.httpClient.post(environment.api + '/email', data);
  }

  public paymentEmail(data: any): Observable<any> {
    return this.httpClient.post(environment.api + '/payu/email', data);
  }

  public gallery(dir: string): Observable<any> {
    return this.httpClient.get(environment.api + '/gallery/' + dir);
  }

  public google_reviews(limit = 0): Observable<Review[]> {
    let query_limit = '';
    if (limit > 0) query_limit = '/' + limit;
    return this.httpClient.get<Review[]>(environment.api + '/google/reviews'+query_limit);
  }

  public google_places(): Observable<any[]> {
    return this.httpClient.get<any[]>(environment.api + '/google/places');
  }

  public google_click(data: any): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.api + '/google/click', data);
  }

  public codegenerate(data: any): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.api + '/simpleform', data);
  }

  public order_confirm(type: string, token: string): Observable<any> {
    return this.httpClient.get<any>(environment.api + '/order/' + type +'/'+ token);
  }

  public reset_password(type: string, token: string): Observable<any> {
    return this.httpClient.get<any>(environment.api + '/auth/' + type +'/'+ token);
  }

  public slides(query: any): Observable<Slides> {
    const params = new HttpParams({fromObject: query});
    params['front'] = true;
    return this.httpClient.get<Slides>(environment.api + '/slides', { params: params});
  }

  public pay(data: any): Observable<any> {
    return this.httpClient.post<any>(environment.api + '/payu/pay', data, {withCredentials: true});
  }

  public payuToken(query: any = null) {
    if (query) {
      const params = new HttpParams({fromObject: query});
      return this.httpClient.get<any>(environment.api + '/payu/token', {params: params, withCredentials: true}); //, {withCredentials: true}
    } else {
      return this.httpClient.get<any>(environment.api + '/payu/token', {withCredentials: true}); //, {withCredentials: true}
    }
  }

  public setMeta(title: string, keywords: string, description: string, canonical: string = '', fbimage: string = '') {
    this.titleService.setTitle(title);
    this.metaService.updateTag({content: description}, 'name="description"');
    this.metaService.updateTag({content: keywords}, 'name="keywords"');

    this.metaService.updateTag({content: title}, 'property="og:title"');
    this.metaService.updateTag({content: description}, 'property="og:description"');

    if (canonical !== '') {
      const head = this.doc.getElementsByTagName('head')[0];
      var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
      if (element==null) {
        element= this.doc.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
      }
      element.setAttribute('rel','canonical')
      element.setAttribute('href',canonical);
    }
    if (fbimage && fbimage !== '') {
      this.metaService.updateTag({content: fbimage}, 'property="og:image"');
    }
  }

  public setLink(path: string) {
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'preload');
    link.setAttribute('as', 'image');
    link.setAttribute('href', path);
    this.doc.head.appendChild(link);
  }

  public setSocial(url: string, title: string, description: string, image: string) {
    this.metaService.updateTag({content: url}, 'property="og:url"');
    this.metaService.updateTag({content: 'Classica - '+title}, 'property="og:title"');
    this.metaService.updateTag({content: description}, 'property="og:description"');
    this.metaService.updateTag({content: image}, 'property="og:image"');
  }
  public showFacebookChatDialog() {
    this.triggerChat.emit(true);
  }

  public getNavigation() {
    return this.navigation;
  }

  public getCurrentPage() {
    return this.currentPage;
  }

  public getBreadcrumbs() {
    return this.breadcrumbs;
  }

  public getCode() {
    return this.code;
  }

  public getMobile() {
    return this.mobile;
  }

  public getClickId() {
    return this.clickId;
  }

  private _currency: string;

  set currency(value: string) {
      this._currency = value;
  }
  get currency(): string {
      return this._currency || 'PLN';
  }
}

