export class Page {
    id: number;
    parent_id: number;
    ord: number;
    module: string;
    path: string;
    path_id: string;
    level: number;
    name: string;
    content: string;
    visible: string;
    visible_in: string;
    title: string;
    keywords: string;
    description: string;
    lang: string;
    children:  any;
    extra_field_1: string;
    extra_field_2: string;
    extra_field_3: string;
    extra_field_4: string;
    extra_field_5: string;
    photos_directory: string;
    template: string;
    search_params: string;
    photo: string;
    fullpath: string;
}

export class SimplePage {
    name: string;
    title: string;
    content: string;
    banner?: string;
    gallery?: any;
    extra_field_1?: string;
    extra_field_2?: string;
    extra_field_3?: string;
    extra_field_4?: string;
    extra_field_5?: string;
}

export class Settings {
    id: number;
    website_title: string;
    website_short_contact: string;
    website_phone: string;
    website_contact: string;
    website_email: string;
    google_use_analytics: number;
    google_key_analytics: string;
    google_use_maps: number;
    google_key_maps: string;
    google_maps_lat: number;
    google_maps_lng: number;
    google_use_captcha: number;
    google_key_captcha: string;
    facebook_use_profile: number;
    facebook_key_profile: string;
    facebook_use_pixel: number;
    facebook_key_pixel: string;
    facebook_title: string;
    facebook_description: string;
    facebook_image: string;
    cookies_use: number;
    cookies_message: string;
    seo_title: string;
    seo_description: string;
    seo_keywords: string;
    social_vimeo: string;
    social_vimeo_toggle: number;
    social_pinterest: string;
    social_pinterest_toggle: number;
    social_instagram: string;
    social_instagram_toggle: number;
    social_instagram_key: string;
    social_instagram_hashtag_toggle: number;
    social_instagram_hashtag: string;
    social_twitter: string;
    social_twitter_toggle: number;
    b2c: number;
    b2b: number;
    b2b_retail: number;
    b2b_retail_price: number;
    freeshipping_enable: boolean;
    freeshipping_value: number;
    freeshipping_b2b_enable: boolean;
    freeshipping_b2b_value: number;
    extra_field_1: string;
    extra_field_2: string;
    extra_field_3: string;
    extra_field_4: string;
    lang: string;
}

export class Setup {
    menu: Page[];
    settings: Settings;
    payments: any;
    carriers: any;
}

export class Popup {
    id: number;
    photos_directory: string;
    name: number;
    content: string;
    photos: PopupPhoto[];
}

export class PopupPhoto {
 id: number;
 filename: string;
 dir: string;
 module: string;
 extension: string;
 alt: string;
 title: string;
 description: string;
 ord: number;
}

export class Downloads {
    items: Download[];
    pages: any;
}

export class Download {
    id: number;
    photos_directory: string;
    name: number;
    photos: DownloadPhoto[];
}

export class DownloadPhoto {
 id: number;
 filename: string;
 extension: string;
 alt: string;
 title: string;
 description: string;
 ord: number;
}

export class Review {
    author_name: string;
    author_url: string;
    profile_photo_url: string;
    rating : number;
    relative_time_description: string;
    text: string;
    time: string;
    city: string;
    height: number;
    state: string;
    id_distributor: number;
}

export class Slide {
    id: number;
    type: string;
    photos_directory: string;
    title: string;
    description: string;
    extra_1: any;
    extra_2: any;
    extra_3: any;
    link: string;
    horizontalalign: string;
    verticalalign: string;
    buttontext: string;
    background: string;
    color: string;
    photos: SlidePhoto[];;
    visible: string;
    lang: string;
  }
  
  export class SlidePhoto {
   id: number;
   filename: string;
   extension: string;
   alt: string;
   title: string;
   description: string;
   ord: number;
  }
  
  export class Slides {
    items: Slide[];
    pages: any;
  }
  