import { isPlatformBrowser } from '@angular/common';
import { Component, Input, OnInit, ChangeDetectionStrategy, Inject, PLATFORM_ID, NgZone, ElementRef } from '@angular/core';
import { interval, map } from 'rxjs';

@Component({
    standalone: true,
    selector: 'app-timer',
    templateUrl: 'timer.component.html',
    styleUrls: ['timer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TimerComponent implements OnInit {

    @Input() public datetime: any;
    public discountTimerActive = false;
    public _diff: number;
    public _days: number;
    public _hours: number;
    public _minutes: number;
    public _seconds: number

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private zone: NgZone, private elementRef : ElementRef) { }

    ngOnInit(): void {

        let date = new Date();
        date.setDate(date.getDate() + 2);
        date.setHours(0,0,0,0);
        
        const date_time = this.datetime.split(' ');
        const parts = date_time[0].split('-');
        const real_date = new Date(parts[0], parts[1] - 1, parts[2]); 
        if (date > real_date) {
            date = real_date;
        }
        
        this.discountTimerActive = true;
        if (isPlatformBrowser(this.platformId) && date) {
            this.zone.runOutsideAngular( () => {
                interval(1000).pipe(
                map((x) => {this._diff = Date.parse(date.toString()) - Date.parse(new Date().toString());
                    })).subscribe((x) => {
                        // this.renderer.setValue(this.getDays(this._diff));
                        this.elementRef.nativeElement.querySelector('#day').innerHTML = this.getDays(this._diff)+'d';
                        this.elementRef.nativeElement.querySelector('#hour').innerHTML = this.getHours(this._diff)+'h';
                        this.elementRef.nativeElement.querySelector('#minute').innerHTML = this.getMinutes(this._diff)+'m';
                        this.elementRef.nativeElement.querySelector('#second').innerHTML = this.getSeconds(this._diff)+'s';
                    });
            });
        }
    }

    getDays(t) {
        return Math.floor( t / (1000 * 60 * 60 * 24) );
    }

    getHours(t) {
        return Math.floor( (t / (1000 * 60 * 60)) % 24 );
    }

    getMinutes(t) {
        return Math.floor( (t / 1000 / 60) % 60 );
    }

    getSeconds(t) {
        return Math.floor( (t / 1000) % 60 );
    }
}
