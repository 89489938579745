
import { HttpClientJsonpModule, HttpClient } from '@angular/common/http';
import { NgModule, ModuleWithProviders, InjectionToken} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';

import { VeComponent } from './ve/ve.component';
import { ProductComponent } from './helpers/product.component';

import { NavigationService } from './navigation/navigation.service';
import { AuthService } from './auth/auth.service';
import { UtilsService } from './services/utils.service';
import { AuthGuard } from './auth/auth-guard.service';
import { CanDeactivateGuard} from './guards/can-deactivate-guard.service'

// helpers
import { DeferLoadDirective } from './helpers/defer-load.directive';
import { PriceComponent } from './helpers/price.component';
import { ImageComponent } from './helpers/image.component';
import { ShowB2bDirective } from './helpers/show-b2b.directive';

// pipes
import { ImgPipe } from './pipes/img.pipe';
import { Observable, of } from 'rxjs';
import { shareReplay, map, catchError } from 'rxjs/operators';
import { SideNavComponent } from './sidenav/side-nav.component';
import { ModalDialogComponent } from './services/modal.component';
import { ModalDialogInstanceService } from './services/modal-instance.service';
import { BreadcrumbsComponent } from './helpers/breadcrumbs.component';
import { ButtonLoaderDirective } from './directives/button-loader.directive';
import { KeyboardFixDirective } from './directives/keyboard-fix.directive';
import { ZoomDirective } from './helpers/zoom.directive';
import { ScrollableComponent } from './helpers/scrollable.component';

export const MAP_LOADED = new InjectionToken<Observable<boolean>>('Google Maps API Loaded');
function loadMapApi(httpClient: HttpClient) {
    return httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyCyxyLkbbI-1WxumOyvPi8_Cbst4uFogsQ', 'callback').pipe(
        shareReplay({bufferSize: 1, refCount: true}),
        map(() => true),
        catchError(e => {
            return of(false);
        })
    );
}

@NgModule({
  imports: [CommonModule, HttpClientJsonpModule, RouterModule, NgOptimizedImage, ZoomDirective, ScrollableComponent],
  declarations: [SideNavComponent, ModalDialogComponent, DeferLoadDirective, VeComponent, ShowB2bDirective, ButtonLoaderDirective, KeyboardFixDirective, PriceComponent, ProductComponent, BreadcrumbsComponent, ImageComponent, ImgPipe],
  exports: [SideNavComponent, CommonModule, RouterModule, DeferLoadDirective, VeComponent, ShowB2bDirective, ButtonLoaderDirective, KeyboardFixDirective, PriceComponent, ProductComponent, BreadcrumbsComponent, ImageComponent, NgOptimizedImage, ImgPipe, ScrollableComponent]
})


export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [NavigationService, UtilsService, AuthService, AuthGuard, CanDeactivateGuard, ModalDialogInstanceService,
        { provide: MAP_LOADED, useFactory: loadMapApi, deps: [HttpClient]}
      ]
    };
  }
}

