import { Component, ElementRef, HostBinding, Input, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html'
})
export class SideNavComponent {

  // @HostBinding('class.open') @Input() isOpen: boolean = false;
  public isOpen = false;
  @Input() overlay: boolean = true;
  @Input() top: number = 0;
  @ViewChild('contentTpl', {read: ViewContainerRef}) contentTpl: ViewContainerRef;

  allListeners: (() => void)[] = [];

  constructor(private renderer: Renderer2, public el:ElementRef) {}

  open() {
    this.isOpen = true;
    this.renderer.addClass(document.body, 'disabled');
    this.renderer.addClass(this.el.nativeElement, 'open');
  }

  close() {
    this.isOpen = false;
    this.renderer.removeClass(document.body, 'disabled');
    this.renderer.removeClass(this.el.nativeElement, 'open');
  }

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }
}