

<div class="attricons flx-col flx-gap-half">
	<span class="attricon flx-col flx-cntr-cntr" *ngFor="let a of attricons">
		<img class="product-icon" alt="CT" [ngSrc]="'diament.svg'" [loaderParams]="{module: 'assets'}" width="23" height="15">
		<span [innerHtml]="a.value_text"></span>
	</span>
</div>

<div class="favorite flx-col flx-gap-half" *ngIf="product.variant_selected">
	<input id="toggle-heart-{{ product.variants[product.variant_selected].id }}" type="checkbox"/>
	<label *ngIf="type != 'favorite'" for="toggle-heart-{{ product.variants[product.variant_selected].id }}" aria-label="Do ulubionych" [class.in_favorites]="in_favorites" (click)="addToFavorites(product.variants[product.variant_selected].id)"><svg class="icon icon-heart-o"><use xlink:href="#icon-heart-o"></use></svg></label>
	<label  *ngIf="type == 'favorite'" class="checkbox"><input type="checkbox" (click)="selectProduct(product.variants[product.variant_selected].id)"/></label>
	<button *ngIf="type == 'favorite'" class="icon-button" aria-label="Do ulubionych" (click)="removeFromFavorites(product.variants[product.variant_selected].id)"><svg class="icon"><use xlink:href="#icon-trash"></use></svg></button>	
</div>

<a (click)="navigate()" [routerLink]="link" [queryParams]="params" aria-label="Produkt" class="product-image">
	<div class="badges" *ngIf="type !== 'small'">
		<span class="badge fastdelivery" [class.red]="product.delivery == '48h' || product.delivery == '24h'">
			<img class="product-icon" *ngIf="product.delivery == '24h' || product.delivery == '48h'" alt="dostawa 48h" [ngSrc]="'dlvr-red.svg'" [loaderParams]="{module: 'assets'}" width="22" height="14">
			<img class="product-icon" *ngIf="product.delivery == '72h' || product.delivery == '2-5 dni'" alt="dostawa 72h" [ngSrc]="'dlvr2.svg'" [loaderParams]="{module: 'assets'}" width="22" height="14">
			<img class="product-icon" *ngIf="product.delivery == '15-30 dni'" alt="dostawa 15-30 dni" [ngSrc]="'dlvr3.svg'" [loaderParams]="{module: 'assets'}" width="22" height="14">
			{{ product.delivery }}
		</span>
	</div>
	<picture *ngIf="product.photo != null">
		<img draggable="false" [alt]="product?.title+' '+alt" [ngSrc]="product.photo"  [loaderParams]="{module: 'products', 'dir': product.photos_directory}" width="400" height="400">
	</picture>
</a>
<div class="card-product-content-wrap">
	<div class="card-product-content">
		<a (click)="navigate()" [routerLink]="link" [queryParams]="params">
			<h3 [innerHtml]="product.title"></h3>
			<h4>{{ alt }}</h4>
		</a> 	
		<ng-container *ngIf="!showSmall">
			<a *appShowB2b="'show_prices'" (click)="navigate()" [routerLink]="link" [queryParams]="params">
				<app-price
				[id]="product.id"
				[price]="product.variants[product.variant_selected].price"
				[price_promo]="product.variants[product.variant_selected].price_promo"
				[currency]="product.variants[product.variant_selected].currency"
				[quantity]="1"
				[tax]="product.variants[product.variant_selected].tax"
				[discountSelected]="discountSelected"
				[manufacturer]="product.id_manufacturer"
				[series]="product.series"
				[category]="product.categories"></app-price>
			</a>
		</ng-container>
	</div>
</div>