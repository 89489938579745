export class Client {
  id: number;
  avatar: any;
  type: number;
  email: string;
  name: string;
  surname: string;
  phone: string;
  sex: string;
  date_of_birth: string;
  state: number;
  confirmations: string;
  date_last_login: string;
  date_created: string;
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
  addresses: ClientAddress[];
  related_to_client: number;
  permissions?: any;
  id_version?: number;
  currency?: string;
  removed: number;
}

export class ClientAddress {
  id: number;
  id_client: number;
  type: string;
  nip: number;
  street: string;
  building: string;
  flat: string;
  postcode: string;
  voivodeship: string;
  city: string;
  country: string;
  phone: string;
  email: string;
  company: string;
  date_created: string;
  date_update: string;
}