import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject ,  Observable ,  BehaviorSubject } from 'rxjs';
import { Products, Product, Filter, Collections, ProductVariant } from './offer.model';
import { environment } from './../../environments/environment';

@Injectable()
export class OfferService {

  product = new Subject<Product>();
  dataChange: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);
  get data(): Product[] { return this.dataChange.value; }

  constructor(private httpClient: HttpClient) {}

  items(query: any): Observable<Products> {
    return this.httpClient.post<Products>(environment.api + '/products', query);
  }

  collections(query: any): Observable<Collections> {
    return this.httpClient.post<Collections>(environment.api + '/collections', query);
  }

  filters(category: number, type = ''): Observable<Filter[]> {
    return this.httpClient.get<Filter[]>(environment.api + '/filters/' + category + (type != '' ? '/'+type : ''));
  }

  banners(id: number): Observable<any> {
    // const params = new HttpParams({fromObject: query});
    return this.httpClient.get<any>(environment.api + '/offer_banners/'+id);
  }

  item(path: string, path_variant: number): Observable<Product> {
    if (path_variant > 0) {
      return this.httpClient.get<Product>(environment.api + '/product/' + path + '/' + path_variant);
    } else {
      return this.httpClient.get<Product>(environment.api + '/product/' + path);
    }
  }

  variant_price(data: any): Observable<Product> {
    return this.httpClient.post<Product>(environment.api + '/product/variant_price', data);
  }

  price(variant: ProductVariant): Observable<Product> {
    return this.httpClient.post<Product>(environment.api + '/product/price', variant);
  }

}

