import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, Renderer2, } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({ standalone: true, selector: '[gridzoom]' })
export class ZoomDirective {

    @Input() url: string;
    @Input() dir: string;
    @Input() urls: any[];

    @HostListener('keydown.escape')
    fn() {
        this.close();
    }

    private cdn = environment.cdn;
    private dialog;

    constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {
       /*constr*/
       el.nativeElement.style.cursor = 'pointer';
    }

    @HostListener('click', ['$event']) onClick($event){
        $event.preventDefault();
        let current = 0;
        let url = this.url;

        this.renderer.addClass(this.document.body, 'disabled');
        this.dialog = document.createElement('photo-dialog');

        const dialogBody = this.renderer.createElement('div');
        this.renderer.addClass(dialogBody, 'photo-dialog-body');
        this.renderer.appendChild(this.dialog, dialogBody);

        // const backdrop = this.renderer.createElement('div');
        // this.renderer.addClass(backdrop, 'backdrop');
        // this.renderer.appendChild(this.dialog, backdrop);

        const navigation = this.renderer.createElement('div');
        this.renderer.addClass(navigation, 'photo-dialog-navigation');
        this.renderer.addClass(navigation, 'flx-row');
        this.renderer.addClass(navigation, 'flx-cntr-cntr');
        this.renderer.appendChild(this.dialog, navigation);

        const loader = this.renderer.createElement('i');
        this.renderer.addClass(loader, 'loader');
        this.renderer.appendChild(this.dialog, loader);

        const img = this.renderer.createElement('img');
        this.renderer.listen(img, 'load', (event) => {

            // console.log(img.naturalWidth, dialogBody.offsetWidth);
            // if (img.naturalWidth > dialogBody.offsetWidth) {
            //     dialogBody.scrollLeft = dialogBody.offsetHeight/4;
            // }
            // if (img.naturalHeight > dialogBody.offsetHeight) {
            //     dialogBody.scrollTop = dialogBody.offsetWidth/4;
            // }

            this.renderer.addClass(this.dialog, 'loaded');
            this.renderer.removeChild(this.dialog, loader);
        });
        this.renderer.listen(img, 'click', (event) => {
            this.close();
        });
        if (this.urls) {
            if (this.urls.length > 1) {

                if (this.urls[0] instanceof Object) {
                    this.urls = this.urls.map(p => ({ ...p, url: p.filename.slice(0, -3)+'webp' }));
                }
                current = this.urls.findIndex(pic => pic.url === url);

                const prev = this.renderer.createElement('button');
                this.renderer.addClass(prev, 'slider-arrow');
                this.renderer.addClass(prev, 'slider-arrow-prev');
                this.renderer.appendChild(navigation, prev);

                const scrollable = this.renderer.createElement('div');
                this.renderer.addClass(scrollable, 'scrollable');
                this.renderer.addClass(scrollable, 'flx-row');
                this.renderer.appendChild(navigation, scrollable);

                let i = 0;
                this.urls.forEach(th => {
                    const th_btn = this.renderer.createElement('button');
                    this.renderer.addClass(th_btn, 'slider-thumb');
                    this.renderer.setAttribute(th_btn, "data-index", ''+i);
                    this.renderer.appendChild(scrollable, th_btn);

                    const th_img = this.renderer.createElement('img');
                    this.renderer.setAttribute(th_img, "src", this.cdn+'/'+this.dir+'/'+this.urls[i].url);
                    this.renderer.appendChild(th_btn, th_img);

                    this.renderer.listen(th_btn, 'click', (event) => {
                        current = +event.currentTarget.getAttribute('data-index');
                        url = this.urls[current].url;
                        this.renderer.setAttribute(img, "src", this.cdn+'/'+this.dir+'/'+url);
                        this.renderer.appendChild(this.dialog, loader);
                    });
                    i++;
                });

                const next = this.renderer.createElement('button');
                this.renderer.addClass(next, 'slider-arrow');
                this.renderer.addClass(next, 'slider-arrow-next');
                this.renderer.appendChild(navigation, next);


                const close = this.renderer.createElement('button');
                this.renderer.addClass(close, 'slider-close');
                this.renderer.appendChild(this.dialog, close);

                this.renderer.listen(prev, 'click', (event) => {
                    current--;
                    if (current < 0) current = this.urls.length-1;
                    url = this.urls[current].url;
                    this.renderer.setAttribute(img, "src", this.cdn+'/'+this.dir+'/'+url);
                    this.renderer.appendChild(this.dialog, loader);
                });

                this.renderer.listen(next, 'click', (event) => {
                    current--;
                    if (current < 0) current = this.urls.length-1;
                    url = this.urls[current].url;
                    this.renderer.setAttribute(img, "src", this.cdn+'/'+this.dir+'/'+url);
                    this.renderer.appendChild(this.dialog, loader);
                });


                this.renderer.listen(close, 'click', (event) => {
                    this.close();
                });
            }
        }

        this.renderer.setAttribute(img, "src", this.cdn+'/'+this.dir+'/'+url);
        this.renderer.appendChild(dialogBody, img);
        this.renderer.appendChild(this.document.body, this.dialog);
    }

    close () {
        this.renderer.removeClass(this.dialog, 'loaded');
        setTimeout(() => {
            this.renderer.removeChild(this.document.body, this.dialog);
            this.renderer.removeClass(this.document.body, 'disabled');
        }, 250);
    }
}
