export const environment = {
  production: true,
  isServer: true,
  version: 1.0,
  // for prerender

  host: 'https://classicatouch.com',
  cdn: 'https://classicatouch.com/uploads',
  api: 'https://classicatouch.com/app',
  socket: 'https://peer.digitalgrid.pl',
  base: 'https://classicatouch.com',
  name: 'Classica',
  currencies: ['PLN'],
  withavatar: false,
  events_exclude: ['bogusia@jubilernieweglowski.pl', 'paulina@jubilernieweglowski.pl', 'classica@jubilernieweglowski.pl', 'natalia@jubilernieweglowski.pl', 'lukasz@jubilernieweglowski.pl', 'damian.martyniuk@milionoczu.pl', 'damian.martyniuk@gmail.com'],
  inpost_src: 'https://geowidget.inpost.pl/inpost-geowidget.js',
  inpost_css: 'https://geowidget.inpost.pl/inpost-geowidget.css',
  inpost: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwMDMzMTA0NzcsImlhdCI6MTY4Nzk1MDQ3NywianRpIjoiY2Q5Y2I1MWMtOGQ0Ny00MGZiLTg3ODAtYTkyN2E5YmYyZTMwIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTplZVl0RmJzUGRLWWVSRVJ4UEhYY1BacFVLQXNtMkU5eUFrYkwwSUtiZm5NIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiZTZjNWE2ZWQtZGNmMC00ZjNlLWIyMTEtM2U5ZmMxOTIzOTMyIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6ImU2YzVhNmVkLWRjZjAtNGYzZS1iMjExLTNlOWZjMTkyMzkzMiIsImFsbG93ZWRfcmVmZXJyZXJzIjoiY2xhc3NpY2F0b3VjaC5jb20iLCJ1dWlkIjoiMDc4M2NhYTgtNjc3MC00NGJkLTk2MzktM2VkMmU3ODhmMjFjIn0.klsvVemQ66En0v6l66l68alF7yxcbk_rGXTQktkvkB-SlUrLessq_tmojcahQ-AvMvm584F-2lHF8u2AVnj11Oe_YkCAutLpKTygxjEtr_a5LedXQ8d1eu9BLTAuNXFMIhzj4PSkUADcjcYAOQFV-SOmCoT6DLxslDsKd0q_bZ8w-zCmSwaHFH46NUYRR1ugGvk-uXz-YrVmgNAD_BVD-JmbMgUHXWN9z3ovGBJMLqsQRTC9Q-yZc0iB3s7gqIe4LdXSU4ax7Rw3klAiWG8Q0DbMf1_qareqlRMf39GBMPt0K2_YVeZmyJXcURLoX0B1xxmRqZ9Qxn20dyYRq_qDJQ'
};
