<div id="master-loader-bar" class="loader" *ngIf="utilsService.isLoading | async"></div>

<side-nav class="master-cart right" #cart>
	<ng-container #cartvc></ng-container>
</side-nav>

<div class="master-header-info flx-dynamic">

	<a class="ue-logo" [routerLink]="['/fundusze-europejskie']"><img [ngSrc]="'ue-logo.jpg'" [loaderParams]="{module: 'assets'}" [alt]="'Projekty UE'" width="522" height="171"></a>

	<div #promocarousel class="carousel">
		<div class="getdiscount animated" (click)="openDiscountDialog(discountSelected)" [style.background-color]="discountSelected.extra_1" [style.color]="discountSelected.extra_2"  *ngIf="discountSelected">
			<span class="mock-button">ODBIERZ KOD</span> <ng-container>pozostało</ng-container> <app-timer [datetime]="discountSelected.valid_to"></app-timer>
		</div>
		<div class="prepay w-100 animated"><span>Zapłać dziś <b>10%</b>, a resztę przy odbiorze</span></div>
		<a  class="reserve w-100 animated" [routerLink]="['/zarezerwuj-spotkanie']" (click)="triggerReservation()"><span>Dotknij i zarezerwuj <b>bezpłatne</b> spotkanie w salonie</span></a>
		<div  class="g-opinion w-100 animated">
			<b>4.95 / 5</b>
			<span class="stars flx-row flx-cntr-cntr flx-gap-half">
				<span class="star full"></span>
				<span class="star full"></span>
				<span class="star full"></span>
				<span class="star full"></span>
				<span class="star full"></span>
			</span>
			w opinii naszych klientów
		</div>

	</div>
</div>

<div class="master-header sticky flx-col">
	<div class="master-header-wrapper gutter flx-row flx-btwn-cntr">

		<div class="master-header-left flx-row flx-strt-cntr">
			<button class="hide-md icon-button hamburger" [class.opened]="mainDrawer?.isOpen" aria-label="Menu" (click)="toggleDrawer()">
					<span class="hamburger-inner"></span>
			</button>
			<a href="#" class="icon-button" aria-label="Zadzwoń do nas" (click)="triggerPhone($event,settings?.website_phone)">
				<svg class="icon icon-phone">
					<use xlink:href="#icon-phone"></use>
				</svg>
			</a>
			<a href="#" class="info hide-xs" [innerHtml]="settings?.website_phone" (click)="triggerPhone($event,settings?.website_phone)"></a>
			&nbsp;<span class="info pink hide-xs" [innerHtml]="settings?.website_short_contact"></span>
		</div>

		<a class="master-logo" [routerLink]="['/']"> {{ pageName }} </a>

		<div class="master-header-right flx-row flx-end-cntr">
			<!-- <form [class.hidden]="!isMobile" class="search" [class.active]="search" novalidate (submit)="searchNavigate($event)">
				<input #term type="text" placeholder="Czego szukasz ?" (focus)="toggleSearch()">
				<button class="icon-button primary" aria-label="Wyszukiwarka" (click)="hideSearch()">
					<svg class="icon icon-search">
						<use xlink:href="#icon-search"></use>
					</svg>
				</button>

				<div class="search-results flx-col" *ngIf="products.length && search">
					<div *ngFor="let element of products">
						<a [routerLink]="['/'+path_offer_details, element.path]" (click)="hideSearch()" class="flx-row flx-strt-cntr flx-gap-default">
							<img src="{{ cdn }}/products/{{ element.photos_directory }}/thumb_{{ element.photo }}"
								alt="{{ element.meta_title }}">

							<div [innerHtml]="element.title"></div>

							<app-price *appShowB2b="'show_prices'"
								[price]="element.variants[element.variant_selected].price"
								[price_promo]="element.variants[element.variant_selected].price_promo"
								[tax]="element.variants[element.variant_selected].tax" 
								[quantity]="1"></app-price>

						</a>
						<hr>
					</div>
					<a [routerLink]="['/'+path_search]" [queryParams]="{title: search_term}" class="flat-button primary"
						(click)="hideSearch()" >zobacz więcej</a>
				</div>
			</form> -->
			<button class="icon-button" aria-label="Zaloguj się" [routerLink]="['/'+(isLoggedIn ? path_account : path_login)]">
				<svg class="icon icon-user-circle-o">
					<use xlink:href="#icon-user-circle-o"></use>
				</svg>
			</button>
			<button class="icon-button hide-xs" aria-label="Ulubione" [routerLink]="['/'+path_favorites]">
				<span class="num_items">{{ favItems }}</span>
				<svg class="icon icon-heart-o">
					<use xlink:href="#icon-heart-o"></use>
				</svg>
			</button>
			<button *appShowB2b="'allow_orders'" class="icon-button" aria-label="Koszyk" id="basket"
				(click)="showCart()">
				<span class="num_items">{{ (cartService.cartSubject | async).products ? (cartService.cartSubject | async).products.length : 0 }}</span>
				<svg class="icon icon-shopping-bag">
					<use xlink:href="#icon-shopping-bag"></use>
				</svg>
			</button>
		</div>
	</div>

</div>

<side-nav [overlay]="true" [top]="drawerTop" class="master-drawer left" #drawer>
	<div class="mobile-navigation-wrap">

		<ul [style.left.%]="-(mobilePath.length*100)" [class.active]="mobilePath.length >= 0" [class.out]="mobilePath.length > 0" (mouseleave)="closeMenu()">
			<ng-template #recursiveList let-navigation>
				<ng-container *ngFor="let page of navigation; let j = index">
					<li *ngIf="allowed.includes(+page.visible)" #listelement>
						<a [href]="page.path" (click)="switchMenuLevel($event, page, listelement)" (mouseenter)="switchMenuLevel($event, page, listelement)" [style]="'transition-delay: '+((j+1)*50)+'ms'" [class.active_item]="mobilePath[+page.level] == page && page.children?.length > 0" [class.out]="mobilePath.length > 0">
							<i class="img-wrap">
								<img *ngIf="page.extra_field_1" [ngSrc]="page.extra_field_1" [loaderParams]="{module: 'pages', 'dir': page.photos_directory}" [alt]="page.name" width="150" height="150">
							</i>
							<span>{{ page.name }}</span>
						</a>
						<ul *ngIf="page.children" [class.active]="mobilePath[+page.level] === page" [class.out]="mobilePath.length-1 > +page.level">
							<div class="flx-col nav-header">
								<div class="nav-header-info"><span>WYBRANO</span><span>{{ page.name }}</span></div>
								<div class="flx-row flx-gap-default-forced">
									<button class="smol" aria-label="Wstecz" (click)="mobilePath.pop()">
									<svg class="icon icon-chevron-left"><use xlink:href="#icon-chevron-left"></use></svg>
									<span>wstecz</span>
									</button>
									<a class="smol" [routerLink]="[mobilePath[+page.level]?.path]">
									<span>zobacz wszystkie </span>
									</a>
								</div>
							</div>
							<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: page.children }"></ng-container>
						</ul>
					</li>
				</ng-container>
			</ng-template>
			<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: navigation }">
			</ng-container>
		</ul>

		<img class="signet" [class.out]="mobilePath.length > 0" [alt]="'Classica'" [ngSrc]="'/sygnet.svg'" [loaderParams]="{module: 'assets'}" width="50" height="50">

		<span class="navigation-backdrop" [style.height.px]="maxHeight" ></span>
	</div>
	<div class="mobile-footer flx-col">

		<span>Poznaj nas lepiej</span>
		<div *ngIf="settings" class="social">
			<a *ngIf="settings.facebook_use_profile" [href]="settings.facebook_key_profile" target="_blank" aria-label="Facebook"
				rel="noopener noreferrer"><span >Profil Facebook</span><svg class="icon icon-facebook-square">
					<use xlink:href="#icon-facebook-square"></use>
				</svg></a>
			<a *ngIf="settings.social_instagram_toggle" [href]="settings.social_instagram" target="_blank" aria-label="Instagram"
				rel="noopener noreferrer"><span >Profil Instagram</span><svg class="icon icon-instagram">
					<use xlink:href="#icon-instagram"></use>
				</svg></a>
			<a *ngIf="settings.social_twitter_toggle" [href]="settings.social_twitter" target="_blank" aria-label="X"
				rel="noopener noreferrer"><span >Profil X</span><svg class="icon icon-twitter-square">
					<use xlink:href="#icon-twitter-square"></use>
				</svg></a>
			<a *ngIf="settings.social_vimeo_toggle" [href]="settings.social_vimeo" target="_blank" aria-label="Youtube"
				rel="noopener noreferrer"><span >Profil Vimeo</span><svg class="icon icon-youtube-squaree">
					<use xlink:href="#icon-youtube-square"></use>
				</svg></a>
			<a *ngIf="settings.social_pinterest_toggle" [href]="settings.social_pinterest" target="_blank" rel=”nofollow” aria-label="Pinterest"
				rel="noopener noreferrer"><span >Profil Pinterest</span><svg class="icon icon-pinterest-square">
					<use xlink:href="#icon-pinterest-square"></use>
				</svg></a>
			<a href="https://www.tiktok.com/@classica_by_nieweglowski" target="_blank" rel=”nofollow” aria-label="TikTok"
				rel="noopener noreferrer"><span >Profil TikTok</span><svg class="icon icon-tiktok-square">
					<use xlink:href="#icon-tiktok-square"></use>
				</svg></a>
		</div>
		<div class="mobile-footer-contact flx-row flx-btwn-cntr">
			<span>SKONTAKTUJ SIĘ</span><div><a href="#" [innerHtml]="settings?.website_phone" (click)="triggerPhone($event,settings?.website_phone)"></a> <span [innerHtml]="settings?.website_short_contact"></span></div>
		</div>
	</div>
</side-nav>

<div class="master-router">
	<router-outlet></router-outlet>
</div>
<ng-container #footervc></ng-container>