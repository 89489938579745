import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy, ViewChild, ElementRef, HostBinding, NgZone, Output, EventEmitter } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { Product, ProductVariant } from './../../offer/offer.model';
import { Discount } from '../cart/cart.model';
import { Subscription, skip } from 'rxjs';
import { environment } from '../../../environments/environment';
import { utils } from './utils';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-product',
  templateUrl: 'product.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe]
})

export class ProductComponent implements OnInit, OnDestroy {

  public cdn = environment.cdn;
  public photo = false;
  public link = [];
  public params = {};
  public loaded = 'none';
  public discounts: Discount[];
  public subDsc: Subscription = null;
  public subFav: Subscription = null;
  public lab = false;
  public quantity = 1;
  public path_offer_detail = '';
  public attricons = [];
  public delivery;
  public showSmall = false;
  public in_favorites = false;
  public alt = '';
  @Input() public type: string;
  @Input() public product: Product;
  @Input() public discountSelected: Discount;
  @Output() public changed = new EventEmitter<any>();
  @ViewChild('quantity') quantityInput: ElementRef;
  @HostBinding('class.b2b') b2b: boolean = false;
  @HostBinding('class.small') small: boolean = false;
  @HostBinding('class.loading-content') loading: boolean = false;
  

  constructor(private router: Router, private cdr: ChangeDetectorRef, private cartService: CartService, private datePipe: DatePipe, private utilsService: UtilsService, private zone: NgZone) {
    this.path_offer_detail = this.router.config.find(x => x.data?.module === 'OfferDetailsModule')?.data.path || '/';
  }

  ngOnInit() {
    if (this.type == 'b2b') this.b2b = true;
    if (this.type == 'small') this.small = true;
    if (this.product.id < 0) this.loading = true;
    if (this.product.id < 0) {
      this.loading = true;
      const tempProductVariant = new ProductVariant();

      this.product.title = '&nbsp;';
      this.product.variants = [tempProductVariant];
      this.product.variant_selected = 0;
    } else {
      if (this.product.categories.length ) {
        if (this.product.categories.findIndex(x => +x.id_page === 65) > 0) {
          this.showSmall = true;
        }
      }
      let added = false;
      let lab = false;
      this.alt = '';
      this.product.delivery = '15-30 dni';
      this.product.variants[0].attributes.forEach((a, i) => {
        if (a.title_safe == 'masa-kamieni' && !added) {
          this.attricons.push(a);
          added = true;
        }
        if (a.title_safe == 'kamienie' && a.value_text == 'Diament Laboratoryjny') {
          lab = true;
        }

        if (a.title_safe == 'czas-dostawy') {
          this.product.delivery = a.value_text;
        } 
        
        if (a.title_safe != 'bestseller') {
          this.alt += a.value_text + ', ';
        }
      });
      this.alt = this.alt.slice(0, -2);
      if (lab && this.attricons.length) {
        this.attricons[0].value_text += '<br>LAB';
      }
      this.link = ['/'+this.path_offer_detail, this.product.path]; //
      this.subFav = this.cartService.favtItems.subscribe(this.favChanged);
    }
    
  }

  addToCart() {
    // let quantity = this.quantityInput.nativeElement.value;
    if (this.quantity < 1) {
      this.quantity = 1;
    }

    // && this.isLoggedIn && this.role == 'client_b2b'
    let supply = $localize `Przewidywana dostawa: nieznana`;
    if (this.product.variants[this.product.variant_selected].date_supply && this.product.variants[this.product.variant_selected].quantity <= 10){
      supply = $localize `Przewidywana dostawa: ` + this.datePipe.transform(this.product.variants[this.product.variant_selected].date_supply, 'shortDate');
    }

    let inzone = NgZone.isInAngularZone();
    if (!inzone) { //fix for slider
      this.zone.run(() => {
        this.cartService.add(+this.product.id, +this.product.variant_id, this.quantity, [], 1, false, '', this.product.extra_1, this.product.extra_2, this.product.extra_3, this.product.extra_4);
      })
    } else {
      this.cartService.add(+this.product.id, +this.product.variant_id, this.quantity, [], 1, false, '', this.product.extra_1, this.product.extra_2, this.product.extra_3, this.product.extra_4);
    }

  }

  addToFavorites(id_product_variant: number) {
    this.cartService.addToFavorites(id_product_variant);
    this.pushData('add_to_wishlist');
  }

  removeFromFavorites(id_product_variant: number) {
    this.cartService.removeFromFavorites([id_product_variant]).then((id:any)=>{
      this.changed.next({id_product_variant: id, delete: true});
    });
  }

  selectProduct(id_product_variant: number) {
    this.changed.next({id_product_variant: id_product_variant, delete: false});
  }

  changeQuantity($event: any) {
    if (+$event.target.value > this.product.variants[0].quantity) {
      this.quantity = this.product.variants[0].quantity;
    } else if (+$event.target.value < 0) {
      this.quantity = 1
    } else {
      this.quantity = $event.target.value;
    }
  }

  plus() {
    // console.log(this.product.variants, this.product.availability);
    if (this.quantity < this.product.variants[0].quantity) {
      this.quantity++;
      // this.cdr.detectChanges();
    }
  }

  minus() {
    if (this.quantity > 1) {
      this.quantity--;
      // this.cdr.detectChanges();
    }
  }

  pushData(event: string) {
    const discounted_price = (this.product.variants[this.product.variant_selected].price_promo ? this.product.variants[this.product.variant_selected].price_promo : this.product.variants[this.product.variant_selected].price);
    const title = this.product.title.split('<br>');
    let item = {
      item_id: this.product.variant_id.toString(),
      item_name: title[0],
      item_category: (this.product.categories.length > 0  ? this.product.categories[this.product.categories.length - 1].name : ''),
      item_brand: this.product.manufacturer,
      item_variant: this.product.title1,      
      price: discounted_price,
      discount: this.product.variants[this.product.variant_selected].price - discounted_price
    };
    this.product.categories.forEach((c: any, i) => {
      if (c.name !== 'ROOT' && c.name !== 'Zobacz wszystkie') {
        item['item_category' + (i > 0 ? (i+1) : '')] = c.name.trim();
      }
    });
    this.utilsService.gtmEcommerce(event, [item]);
  }

  navigate() {
    let inzone = NgZone.isInAngularZone();
    if (!inzone) { //fix for slider
        this.zone.run(() => {
          this.pushData('select_item');
          this.router.navigate(this.link, { queryParams: this.params });
        })
    } else {
      this.pushData('select_item');
      this.router.navigate(this.link, { queryParams: this.params });
    }
  }

  favChanged = (products: Product[]) => {
    if (products.findIndex(p => +p.variant_id == +this.product.variant_id) > -1) {
      this.in_favorites = true;
    }
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    if (this.subDsc) {
      this.subDsc.unsubscribe();
    }
  }

}
