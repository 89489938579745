<div class="price" [class.discounted]="discounted">
<div class="price-regular">
{{ quantity * (new_price && cart ? new_price : old_price) | currency:currency:'symbol':'1.0-2' }}<br><i>{{ discounted && cart == true ? 'Cena promocyjna' : 'Cena regularna' }}</i>
</div>
<span *ngIf="discounted && cart" class="normal">{{ quantity * old_price | currency:currency:'symbol':'1.0-2' }}<br><i>Cena regularna</i></span>
</div>	
<div class="price-old" [class.discounted]="discounted" *ngIf="discounted">
	<span class="history" *ngIf="history > 0">
		Najniższa cena z 30 dni: {{ history | currency:currency:'symbol':'1.0-2' }}
	</span>
</div>
<div class="price-withcode" *ngIf="new_price_withcode">
	<b>-{{ discountSelected.value | number:'1.0-0' }}%</b>
	<div class="flx-col">
		<i>{{ discounted == true ? 'Cena promocyjna' : 'Cena z kodem' }}</i>
		<span>{{ new_price_withcode | currency:currency:'symbol':'1.0-2' }}</span>
	</div>
</div>