import { ChangeDetectorRef, Component, NgZone, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../offer/offer.model';
import { OfferService } from '../offer/offer.service';


@Component({
  selector: 'app-notfound',
  templateUrl: 'notfound.component.html',
  styleUrls: ['notfound.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NotfoundComponent {
  public path_offer = '';
  public path_contact = '';
  public products: Product[] = [];
  public sitename = environment.name;

  constructor(public zone: NgZone, private router: Router, private cdr: ChangeDetectorRef, private offerService: OfferService) {
    this.path_offer = this.router.config.find(r => r.data?.module === 'OfferModule' && +r.data.level === 0)?.path || '/';
    this.path_contact = this.router.config.find(r => r.data?.module === 'ContactModule')?.path || '/';
    const tempProduct = new Product();
    tempProduct.id = -1;
    this.products = Array(4).fill(tempProduct);
  }

  fetchFeatured() {
    this.offerService.items({ limit: 16, rand: true, day: true }).pipe(take(1)).subscribe(result => {
      this.zone.run(() => {
        this.products = result.items;
        this.cdr.detectChanges();
      });
    });
  }

}
