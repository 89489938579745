import { ViewContainerRef, Inject, Injectable, ApplicationRef, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';


import { ModalDialogComponent } from './modal.component';
import { IModalDialogOptions } from './modal.interface';
import { ModalDialogInstanceService } from './modal-instance.service';
import { AppComponent } from 'src/app/app.component';
import { isPlatformBrowser } from '@angular/common';
import { NavigationService } from '../navigation/navigation.service';


@Injectable()
export class UtilsService {

  public isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isFullscreenSubject = new BehaviorSubject<boolean>(false);
  public isLoadingBool = true;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(ModalDialogInstanceService) private modalDialogInstanceService: ModalDialogInstanceService, private appRef: ApplicationRef, private navigationService: NavigationService) {
  }

  get isLoading(): Observable<boolean> {
    return this.isLoadingSubject.asObservable().pipe(share());
  }

  get isFullscreen(): Observable<boolean> {
    return this.isFullscreenSubject.asObservable().pipe(share());
  }

  public setLoading() {
    if (!this.isLoadingBool) {
      this.isLoadingBool = true;
      this.isLoadingSubject.next(true);
    }
  }

  public clearLoading() {
    if (this.isLoadingBool) {
        this.isLoadingBool = false;
        this.isLoadingSubject.next(false);
    }
  }

  public checkCookie() {
    const cookies = document.cookie.split( " " );

    let fbp, fbc, uetsid, uetvid, client_user_agent, client_ip_address  = null;

    for ( let i = 0; i < cookies.length; i++ ) {

        let cookie = cookies[i].split( "=" );

        if (cookie[0] == '_fbp') fbp = cookie[1].slice(0, -1);
        if (cookie[0] == '_fbc') fbc = cookie[1].slice(0, -1);
        if (cookie[0] == '_uetsid') uetsid = cookie[1].slice(0, -1);
        if (cookie[0] == '_uetvid') uetvid = cookie[1].slice(0, -1);
      
    }

    client_user_agent = window.navigator.userAgent;
    client_ip_address = this.navigationService.ipAddress;

    return [fbp, fbc, client_user_agent, client_ip_address, uetsid, uetvid];
  }

  public gtmEcommerce(event_name, items: any, additional: any = null) {
    // console.log('=========================GTM========================='); 

    if (isPlatformBrowser(this.platformId)) {


        let ecommerceData:any;
        if (additional) {
          ecommerceData = additional;
        } else {
          ecommerceData = {};
        }
        ecommerceData.items = items.map(obj => ({ ...obj, google_business_vertical: 'retail' }));
        ecommerceData.currency = 'PLN';

        const cookieData = this.checkCookie();
        if (cookieData[0]) ecommerceData['fbp'] = cookieData[0];
        if (cookieData[1]) ecommerceData['fbc'] = cookieData[1];
        if (cookieData[2]) ecommerceData['client_user_agent'] = cookieData[2];
        if (cookieData[3]) ecommerceData['client_ip_address'] = cookieData[3];
        if (cookieData[4]) ecommerceData['uetsid'] = cookieData[4];
        if (cookieData[5]) ecommerceData['uetvid'] = cookieData[5];
        let uuid = localStorage.getItem('uuid') || null;
        if (uuid) {
          ecommerceData['fb_login_id'] = uuid;
        }
        let id_client = localStorage.getItem('user_id') || null;
        if (id_client) {
          ecommerceData['fb_login_id'] = id_client;
        }
        // console.log({
        //   event: event_name,
        //   ecommerce: ecommerceData
        // }); 

        if ((<any>window).dataLayer) {
          (<any>window).dataLayer.push({ ecommerce: null });
          (<any>window).dataLayer.push({
            event: event_name,
            ecommerce: ecommerceData
          });
        }
    }

    // console.log('=========================GTM========================='); 
  }

  public gtmCustom(event_name, additional: any = null) {
  
    // console.log('=========================GTM========================='); 

    if (isPlatformBrowser(this.platformId)) {

        this.checkCookie();

        let layer:any = {event: event_name}
        if (additional) {
          layer = {...layer, ...additional};
        }
        const cookieData = this.checkCookie();
        if (cookieData[0]) layer['fbp'] = cookieData[0];
        if (cookieData[1]) layer['fbc'] = cookieData[1];
        if (cookieData[2]) layer['client_user_agent'] = cookieData[2];
        if (cookieData[3]) layer['client_ip_address'] = cookieData[3];
        if (cookieData[4]) layer['uetsid'] = cookieData[4];
        if (cookieData[5]) layer['uetvid'] = cookieData[5];
        let uuid = localStorage.getItem('uuid') || null;
        if (uuid) {
          layer['fb_login_id'] = uuid;
        }
        let id_client = localStorage.getItem('user_id') || null;
        if (id_client) {
          layer['fb_login_id'] = id_client;
        }

        if ((<any>window).dataLayer) {
          (<any>window).dataLayer.push(layer);
        }
    }

    // console.log('=========================GTM========================='); 
  }


  public openDialog(options: Partial<IModalDialogOptions<any>> = {}, target: ViewContainerRef = null) {
    setTimeout(() => {
      if (!options.placeOnTop) {
        this.modalDialogInstanceService.closeAnyExistingModalDialog();
      }
      if (target == null) {
        target = (this.appRef.components[0].instance as AppComponent).viewRef;
      } 
      const componentRef = target.createComponent(ModalDialogComponent);
      componentRef.instance.dialogInit(componentRef, options);

      this.modalDialogInstanceService.saveExistingModalDialog(componentRef);
    }, 0);
  }

}

