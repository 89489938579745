type parser = {
    parse(OutputData: any): Array<string>;
    parseStrict(OutputData: any): Array<string> | Error;
    parseBlock(block: block): string;
    validate(OutputData: any): Array<string>;
};

type transforms = {
    [key: string]: any;
    delimiter(): string;
    header(block: block): string;
    paragraph(block: block): string;
    list(block: block): string;
    image(block: block): string;
    quote(block: block): string;
    code(block: block): string;
    embed(block: block): string;
};

type ListItem = {
    content: string;
    items: Array<ListItem>;
};

type block = {
    type: string;
    data: {
        text?: string;
        level?: number;
        caption?: string;
        url?: string;
        file?: {
            url?: string;
        };
        stretched?: boolean;
        withBackground?: boolean;
        withBorder?: boolean;
        items?: Array<string> | Array<ListItem>;
        style?: string;
        code?: string;
        service?: "vimeo" | "youtube";
        source?: string;
        embed?: string;
        width?: number;
        height?: number;
    };
    tunes?: any;
};


const transforms: transforms = {
    delimiter: () => {
        return `<br/>`;
    },

    button: ({ data, tunes }) => {
        let align = "text-center";
        if (tunes['alignment']) {
            align = tunes['alignment']['alignment'];
        }
        return data;
    },

    popup: ({ data, tunes }) => {
        let align = "text-center";
        if (tunes['alignment']) {
            align = tunes['alignment']['alignment'];
        }
        return data;
    },

    header: ({ data, tunes }) => {
        let align = "text-left";
        if (tunes['alignment']) {
            align = tunes['alignment']['alignment'];
        }
        return `<h${data.level} class="text-${align}">${data.text}</h${data.level}>`;
    },

    paragraph: ({ data, tunes }) => {
        let align = "text-left";
        if (tunes['alignment']) {
            align = tunes['alignment']['alignment'];
        }
        return `<p class="ce-paragraph text-${align}">${data.text}</p>`;
    },

    list: ({ data }) => {
        const listStyle = data.style === "unordered" ? "ul" : "ol";

        const recursor = (items: any, listStyle: string) => {
            const list = items.map((item: any) => {
                if (!item.content && !item.items) return `<li class="cdx-list__item">${item}</li>`;

                let list = "";
                if (item.items) list = recursor(item.items, listStyle);
                if (item.content) return `<li class="cdx-list__item"> ${item.content} </li>` + list;
            });

            return `<${listStyle} class="cdx-list">${list.join("")}</${listStyle}>`;
        };

        return recursor(data.items, listStyle);
    },

    image: ({ data }) => {
        let caption = data.caption ? data.caption : "Image";
        return '';
    },
    quote: ({ data }) => {
        return `<blockquote>${data.text}</blockquote> - ${data.caption}`;
    },

    code: ({ data }) => {
        return `<pre><code>${data.code}</code></pre>`;
    },

    embed: ({ data }) => {
        switch (data.service) {
            case "vimeo":
                return `<iframe src="${data.embed}" height="${data.height}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
            case "youtube":
                return `<iframe width="${data.width}" height="${data.height}" src="${data.embed}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
            default:
                throw new Error(
                    "Only Youtube and Vime Embeds are supported right now."
                );
        }
    },
    

    table: ({ data }) => {
        const rows = data.content.map((row, index) => {
            let el = 'td';
            if (data.withHeadings && index == 0) {
                el = 'th';
            } 
            return `<tr class="tc-row">${row.reduce((acc, cell) => acc + `<`+el+` class="tc-cell">${cell}</`+el+`>`, "")}</tr>`;
        });
        if (data.withHeadings) {
            const head = rows[0];
            delete(rows[0]);
            const body = rows.join("");
            return `<table class="tc-table"><thead>${head}</thead><tbody>${body}</tbody></table>`;
        } else {
            return `<table class="tc-table"><tbody>${rows.join("")}</tbody></table>`;
        }
        
    },

    raw: ({ data }) => {
        return data.html;
    }

};

export class Parser {

    public plugins: any;
    public parsers: any;

    constructor(plugins = {}) {
        this.parsers = Object.assign({}, transforms, plugins);
    }

    parseBlock(block) {
        return this.parsers[block.type]
            ? this.parsers[block.type](block)
            : this.ParseFunctionError(block.type);
    }

    ParseFunctionError(type: string) {
        return new Error(`\x1b[31m The Parser function of type "${type}" is not defined.`);
    }
}

// export default Parser;