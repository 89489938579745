import { Injectable, Inject, PLATFORM_ID, DEFAULT_CURRENCY_CODE, NgZone, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { share, shareReplay, skip, take } from 'rxjs/operators';
import { Cart, CartProduct, Discounts, Discount } from './cart.model';
import { UtilsService } from '../services/utils.service';
import { environment } from './../../../environments/environment';
import { Product, Products } from 'src/app/offer/offer.model';
import { utils } from '../helpers/utils';

@Injectable()
export class CartService {

  public cartSubject = new BehaviorSubject<Cart>(new Cart());
  public cartItems = new BehaviorSubject<number>(0);
  public favtItems = new BehaviorSubject<Product[]>([]);
  public discounts$ = new BehaviorSubject<Discount[]>([]);
  public total = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(DEFAULT_CURRENCY_CODE) private currency: string, private httpClient: HttpClient, private utilsService: UtilsService, private zone: NgZone) {
  }
  get cart(): Observable<Cart> {
    return this.cartSubject.asObservable().pipe(share());
  }

  item(id: number): Observable<Cart> {
    return this.httpClient.get<Cart>(environment.api + '/cart/' + id);
  }

  change(cart: Cart): Observable<Cart> {
    return this.httpClient.post<Cart>(environment.api + '/cart', cart);
  }

  changeProduct(product: CartProduct): Observable<Cart> {
    return this.httpClient.post<Cart>(environment.api + '/cart_product', product);
  }

  fetchCart() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('id_cart') !== null) {
        const id_cart = +localStorage.getItem('id_cart');
        this.item(id_cart).subscribe((cart_result: Cart) => {
          if (cart_result) {
            this.total = cart_result.products.reduce(function (a, b) { return +a + +b.quantity; }, 0);
            this.cartSubject.next(cart_result);
          } else {
            localStorage.removeItem('id_cart');
          }
          this.cartItems.next(this.total);
        });
      } else {
        this.cartItems.next(this.total);
      }
    }
  }

  add(id_product: number, id_variant: number, quantity: number = 1, attributes: any = null, availability: number = 1, ommit_availability: boolean = false, availability_txt: string = '', extra_1: string = null, extra_2: string = null, extra_3: string = null, extra_4: string = null, custom_price: number = null, id_configuration: string = null, id_cart_product: number = null, code = null, id_version = null):Promise<boolean> {
    if (+availability === 6) {
      // automatic
    } else {
      if (+availability > 4 && !ommit_availability) {
        this.openDialog(id_product, id_variant, quantity, availability_txt);
        return;
      }
    }

    this.utilsService.setLoading();
    let id_cart = null;
    let id_user = null;
    let user_role = null;
    if (isPlatformBrowser(this.platformId)) {
      id_user = +localStorage.getItem('user_id') || null;
      user_role = +localStorage.getItem('user_role');
      // this.currency = localStorage.getItem('user_currency') || 'PLN';
      if (localStorage.getItem('id_cart') !== null) {
        id_cart = +localStorage.getItem('id_cart');
      }
    }

    const cartProduct: CartProduct = {
      id: id_cart_product,
      id_cart: null,
      id_version: id_version,
      id_product: id_product as number,
      id_product_variant: id_variant as number,
      id_configuration: id_configuration as string,
      quantity: quantity as number,
      title: null,
      series: null,
      price: null,
      price_promo: null,
      custom_price: custom_price,
      tax: null,
      state: null,
      width: null,
      height: null,
      length: null,
      dimension_units: null,
      weight: null,
      weight_units: null,
      attr_name: null,
      categories: null,
      categories_names: null,
      manufacturer: null,
      manufacturer_id: null,
      sku: null,
      availability: null,
      availability_txt: null,
      discounts: null,
      discounted_price: null,
      discounted_price_value: null,
      discounted_price_code: null,
      extra_1: extra_1,
      extra_2: extra_2,
      extra_3: extra_3,
      extra_4: extra_4,
      attributes: attributes
    };

    const cart: Cart = {
      id: id_cart,
      id_carrier: null,
      id_payment: null,
      id_address_delivery: null,
      id_address_invoice: null,
      vat: 0,
      currency: this.currency,
      id_client: id_user,
      code: code,
      data: '' as string,
      comment_carrier: null,
      comment_shop: null,
      self_pickup: 0,
      ommit_availability: ommit_availability,
      products: [cartProduct],
      total: null,
      discount_value: null,
      discount: null,
      error: null
    };

    // console.log('ADD::', cart); 

    return new Promise(resolve=>{
      this.change(cart).subscribe((cart_result: Cart) => {

        if (cart_result.error === 1) {
          this.utilsService.openDialog({isSnackbar: 'error',
          content: $localize `Dodałeś zbyt dużą ilość tego produkt - ustawiliśmy maksymalną dostępną ilość w koszyku.`,
          actionButtons: [{ text: 'OK', onAction: () => true }]});
          this.utilsService.clearLoading();
          return;
        }

        if (cart_result.error === 2 && !ommit_availability) {
          this.openDialog(id_product, id_variant, quantity, availability_txt);
          this.utilsService.clearLoading();
          return;
        }

        if (+cart_result.id_client !== +id_user) {
          if (isPlatformBrowser(this.platformId)) {
            id_user = null;
            localStorage.removeItem('user_id');
          }
        }
        this.cartSubject.next(cart_result);
        this.total = cart_result.products.reduce(function (a, b) { return +a + +b.quantity; }, 0);
        this.cartItems.next(this.total);
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('id_cart', cart_result.id.toString());
        }
        
        this.utilsService.clearLoading();
        resolve(true);
        if (!NgZone.isInAngularZone()) this.zone.run(() => {});
      }, err => {
        this.utilsService.openDialog({isSnackbar: 'error',
          content: $localize `Wystąpił nieoczekiwany błąd, spróbuj ponownie lub skontaktuj się z nami.`,
          actionButtons: [{ text: 'OK', onAction: () => true }]});
        this.utilsService.clearLoading();
        if (!NgZone.isInAngularZone()) this.zone.run(() => {});
        resolve(false);
      });
    });
  }

  addToFavorites(id_product_variant: number, extra_field_1 = null, attributes = null) {
    this.utilsService.setLoading();
    let id_client = null;
    let uuid = null;
    if (isPlatformBrowser(this.platformId)) {
      id_client = +localStorage.getItem('user_id') || null;
      uuid = localStorage.getItem('uuid') || null;
    }

    if (id_client == null && uuid == null) {
      uuid = utils.uuidv4();
      localStorage.setItem('uuid', uuid);
    }


    this.httpClient.post<Products>(environment.api + '/favorites', {id_product_variant: id_product_variant, id_client: id_client, uuid: uuid, extra_field_1: extra_field_1, attributes: attributes}).pipe(take(1)).subscribe({
        next: (v) => {
          this.utilsService.openDialog({isSnackbar: 'success',
          content: $localize `Produkt został dodany do Twojego schowka.`,
          actionButtons: [{ text: 'OK', onAction: () => true }]});
          this.favtItems.next(v?.items || []);
        },
        error: (e) => {
          this.utilsService.openDialog({isSnackbar: 'error',
          content: $localize `Wystąpił nieoczekiwany błąd, spróbuj ponownie lub skontaktuj się z nami.`,
          actionButtons: [{ text: 'OK', onAction: () => true }]});
        },
        complete: () =>{
          this.utilsService.clearLoading();
        }
    });
  }

  removeFromFavorites(id_product_variant: number[]):Promise<number[]> {
    this.utilsService.setLoading();
    let id_client = null;
    let uuid = null;
    if (isPlatformBrowser(this.platformId)) {
      id_client = +localStorage.getItem('user_id') || null;
      uuid = localStorage.getItem('uuid') || null;
    }

    if (id_client == null && uuid == null) {
      uuid = utils.uuidv4();
      localStorage.setItem('uuid', uuid);
    }

    let count = id_product_variant.length;
    let result = new Observable();
    return new Promise(resolve=>{
      this.httpClient.post<Products>(environment.api + '/favorites', {id_product_variant: id_product_variant, id_client: id_client, uuid: uuid, delete: true }).pipe(take(1)).subscribe({
          next: (v) => {
            let msg = (count > 1 ? $localize `Produkty zostały usuniętę z Twojego schowka.` :  $localize `Produkt został usunięty z Twojego schowka.`);
            this.utilsService.openDialog({isSnackbar: 'success',
            content: msg,
            actionButtons: [{ text: 'OK', onAction: () => true }]});

            this.favtItems.next(v?.items || []);

            resolve(id_product_variant);
          },
          error: (e) => {
            this.utilsService.openDialog({isSnackbar: 'error',
            content: $localize `Wystąpił nieoczekiwany błąd, spróbuj ponownie lub skontaktuj się z nami.`,
            actionButtons: [{ text: 'OK', onAction: () => true }]});
            resolve([]);
          },
          complete: () =>{
            this.utilsService.clearLoading();
            
          }
      });
    });

  }

  delete(id: number) {
    //gtm
    const product = this.cartSubject.value.products.find(x => x.id === id);
    if (product) {
      let attr = '';
      if (product.attributes) {
        attr = product.attributes.map(item => item.attr_value).join(', ');
      }
      const discounted_price = (product.price_promo > 0 ? product.price_promo : product.price);
      let i = 1;
      let item = {
        item_name: product.title,
        item_id: product.id_product_variant.toString(),
        price: discounted_price,
        discount: product.price - discounted_price,
        item_brand: product.manufacturer,
        item_variant: attr,
        quantity: product.quantity,
        currency: 'PLN'
      };
      product.categories.forEach((c: any, i) => {
        if (c.name !== 'ROOT' && c.name !== 'Zobacz wszystkie') {
          item['item_category' + (i > 0 ? (i+1) : '')] = c.name.trim();
        }
      });
      this.utilsService.gtmEcommerce('remove_from_cart', [item]);
    }

    this.httpClient.post<Cart>(environment.api + '/cart', { 'id': id, 'id_cart': this.cartSubject.value.id, 'delete': true }).pipe(take(1)).subscribe(result => {
      this.cartSubject.next(result);
    });
  }

  updatePrice(id: number, custom_photo: string, custom_price: number, variant: number, attributes: any = null): Observable<Cart> {
    return this.httpClient.post<Cart>(environment.api + '/cart', { 'id': id, 'custom_price': custom_price, 'custom_photo': custom_photo, id_variant: variant, attributes: attributes });
  }

  updateCurrency(newCurrency: string) {

    this.utilsService.setLoading();
    let id_cart = null;
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('id_cart') !== null) {
        id_cart = +localStorage.getItem('id_cart');
      }
    }

    if (id_cart) {

      const cart: Cart = {
        id: id_cart,
        id_carrier: null,
        id_payment: null,
        id_address_delivery: null,
        id_address_invoice: null,
        vat: null,
        currency: newCurrency,
        id_client: null,
        code: null,
        data: null,
        comment_carrier: null,
        comment_shop: null,
        self_pickup: null,
        ommit_availability: null,
        products: null,
        total: null,
        discount_value: null,
        discount: null,
        error: null
      };

      this.change(cart).subscribe(() => {});
    } else {
    }
  }

  clear() {
    this.cartSubject.next(new Cart());
    this.cartItems.next(0);
    this.total = 0;
  }

  // 1 - home
  // 2 - checkout
  // 3 - client

  discounts(query: any): Observable<Discounts> {
    return this.httpClient.post<Discounts>(environment.api + '/discount', query).pipe(shareReplay(1));
  }

  fetchDiscounts(query: any) {
    this.httpClient.post<Discounts>(environment.api + '/discount', query).pipe(take(1)).subscribe(discounts => {
      this.discounts$.next(discounts.items);
    });
  }

  openDialog(id_product: number, id_variant: number, quantity: number, availability_txt: string = '') {
    // if (!NgZone.isInAngularZone()) 
    this.zone.run(() => {
      this.utilsService.openDialog({
        content: $localize `<h4>Ten produkt jest dostępny tylko na zamówienie.</h4><br>Możesz go dodać do koszyka, a obsługa skontaktuje się z Tobą w celu ustalenia szczegółów. W kolejnym kroku możesz dopisać uwagi dla sklepu.<br>` + availability_txt + '<br>',
        actionButtons: [
          { text: 'anuluj', buttonClass:'flat-button warn', onAction: () => true},
          { text: 'dodaj', buttonClass:'flat-button primary', onAction: () => new Promise((resolve: any) => { 
            this.add(id_product, id_variant, quantity, null, 1, true);
            resolve(true);
          })}
        ],
      });
    });
  }

}
