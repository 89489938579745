import {Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy, Output, EventEmitter, ViewContainerRef, ViewEncapsulation, ViewChild, AfterViewInit} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { Parser } from './parser';
import { CommonModule, Location, NgOptimizedImage } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { BehaviorSubject, take } from 'rxjs';
import { Product, Products } from 'src/app/offer/offer.model';
import { OfferService } from 'src/app/offer/offer.service';

@Component({
  selector: 'app-ve',
  templateUrl: 've.component.html',
  styleUrls: ['ve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {ngSkipHydration: 'true'},
})


export class VeComponent implements OnInit, AfterViewInit, OnDestroy {
  public edit = false;
  public padded = true;
  public parser: Parser;
  public cdn = environment.cdn;
  public safeContent: SafeHtml;
  public products: BehaviorSubject<Products>;
  @Input('content') public content: any;
  @Input('module') public module: string;
  @Input('dir') public dir: string;
  @Output() public changed: EventEmitter<any> = new EventEmitter();
  @ViewChild('editorvc', {read: ViewContainerRef}) editorvc: ViewContainerRef;

  constructor(private location: Location, private router: Router, private ds: DomSanitizer, private offerService: OfferService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.parser = new Parser({});
    const currentUrl = this.location.path();
    this.edit = currentUrl.includes('gridpreview');


    const tempProduct = new Product();
    tempProduct.id = -1;
    this.products = new BehaviorSubject({pages: {itemCountPerPage: 0}, items: Array(4).fill(tempProduct)});
  }

  ngAfterViewInit(): void {
    if (this.edit && this.editorvc) {
      this.enableEditor();
    }
  }

  parseBlock(block){
    const html = this.parser.parseBlock(block);
    return html;
  }

  emitClick(type: string, data: any) {
    this.changed.emit({type: type, data: data});
  }

  async enableEditor() {
    const { VisualeditorComponent } = await import('./../../visualeditor/visualeditor.component');
    const cmp = VisualeditorComponent;
    let editorRef = this.editorvc.createComponent(cmp, {index: 0});
    editorRef.instance.content = this.content;
    editorRef.instance.module = this.module;
    editorRef.instance.dir = this.dir;
    editorRef.instance.contentChange.subscribe(data => {
      this.changed.emit(data);
    });
    this.cdr.detectChanges();
  }

  fetchProducts(ids: number[]) {
    this.offerService.items({variant_ids: ids.join(',')}).pipe(take(1)).subscribe(result => {
      this.products.next(result);
    });
  }

  ngOnDestroy() {
  }

}
