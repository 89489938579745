import { Page } from '../navigation/pages.model';



export const utils = {


    filterData(data: any[], modifier: string, type: string, section: string, id = 0, parent_id = 0) {

        if (parent_id === 1) {
            parent_id = 0; // reset to 0 on top level or we get all top level items via parent id
        }

        var r = data.filter((page: Page) =>  {
            if (page.children) page.children = this.filterData(page.children, modifier, type, section, id, parent_id);
            if (modifier === '!==') {
                return (page.module !== type && +page.visible === 1 && page.visible_in.includes(section))
            } else if (modifier === '===') {
                return (page.module === type && +page.visible === 1 && page.visible_in.includes(section))
            } else if (id > 0 && section){  // in section
                return ((+page.parent_id === id || +page.id === id || +page.parent_id === parent_id || +page.id === parent_id) && +page.visible === 1 && page.visible_in.includes(section))
            } else if (id > 0 && !section){ // whatever
                return ((+page.parent_id === id || +page.id === id || +page.parent_id === parent_id || +page.id === parent_id) && +page.visible === 1)
            }
        })
        return r;
    },

    scrollTo(to: number, callback: any, duration: number) {
        // because it's so fucking difficult to detect the scrolling element, just move them all
        function move(amount) {
            document.documentElement.scrollTop = amount;
            document.body.scrollTop = amount;
        }
        function position() {
            return document.documentElement.scrollTop  || document.body.scrollTop;
        }

        var requestAnimFrame = (function(){
            return  window.requestAnimationFrame || function( callback ){ window.setTimeout(callback, 1000 / 60); };
        })();

        var start = position(),
            change = to - start,
            currentTime = 0,
            increment = 20;
        duration = (typeof(duration) === 'undefined') ? 500 : duration;
        var animateScroll = function() {
            // increment the time
            currentTime += increment;
            // find the value with the quadratic in-out easing function
            var val = utils.easeInOutQuad(currentTime, start, change, duration);
            // move the document.body
            move(val);
            // do the animation unless its over
            if (currentTime < duration) {
                requestAnimFrame(animateScroll);
            } else {
            if (callback && typeof(callback) === 'function') {
                // the animation is done so lets callback
                callback();
            }
            }
        };
        animateScroll();
    },

    uuidv4() {
      return (<any>[1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },

    easeInOutQuad (t, b, c, d) {
        t /= d/2;
        if (t < 1) {
            return c/2*t*t + b
        }
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    },

    getLink(url: string) {
      const res = url.split('?');
      return res[0];
    },
    
    getParams(queryString: string) {
      const q = {};
      const paramsPart = queryString.split('?')[1];
      if (paramsPart) {
        const pairs = (paramsPart[0] === '?' ? paramsPart.substr(1) : paramsPart).split('&');
        for (let i = 0; i < pairs.length; i++) {
          const pair = pairs[i].split('=');
          q[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
      }
      return q;
    },

    product_extras: [

    ],
    client_extras: [
  
    ],
    countries: [
      // {value: 'DZ', viewValue:   `Algieria`},
      // {value: 'AD', viewValue:   `Andora`},
      // {value: 'AO', viewValue:   `Angola`},
      // {value: 'AI', viewValue:   `Anguilla`},
      // {value: 'AQ', viewValue:   `Antarktyka`},
      // {value: 'AG', viewValue:   `Antigua i Barbuda`},
      // {value: 'SA', viewValue:   `Arabia Saudyjska`},
      // {value: 'AR', viewValue:   `Argentyna`},
      // {value: 'AM', viewValue:   `Armenia`},
      // {value: 'AW', viewValue:   `Aruba`},
      // {value: 'AU', viewValue:   `Australia`},
      {value: 'AT', viewValue:   `Austria`},
      // {value: 'AZ', viewValue:   `Azerbejdżan`},
      // {value: 'BS', viewValue:   `Bahamy`},
      // {value: 'BH', viewValue:   `Bahrajn`},
      // {value: 'BD', viewValue:   `Bangladesz`},
      // {value: 'BB', viewValue:   `Barbados`},
      {value: 'BE', viewValue:   `Belgia`},
      // {value: 'BZ', viewValue:   `Belize`},
      // {value: 'BJ', viewValue:   `Benin`},
      // {value: 'BM', viewValue:   `Bermudy`},
      // {value: 'BT', viewValue:   `Bhutan`},
      {value: 'BY', viewValue:   `Białoruś`},
      // {value: 'BO', viewValue:   `Boliwia`},
      // {value: 'BQ', viewValue:   `Bonaire, Sint Eustatius i Saba`},
      {value: 'BA', viewValue:   `Bośnia i Hercegowina`},
      // {value: 'BW', viewValue:   `Botswana`},
      // {value: 'BR', viewValue:   `Brazylia`},
      // {value: 'BN', viewValue:   `Brunei`},
      // {value: 'IO', viewValue:   `Brytyjskie Terytorium Oceanu Indyjskiego`},
      // {value: 'VG', viewValue:   `Brytyjskie Wyspy Dziewicze`},
      // {value: 'BG', viewValue:   `Bułgaria`},
      // {value: 'BF', viewValue:   `Burkina Faso`},
      // {value: 'BI', viewValue:   `Burundi`},
      // {value: 'CL', viewValue:   `Chile`},
      // {value: 'CN', viewValue:   `Chiny`},
      {value: 'HR', viewValue:   `Chorwacja`},
      // {value: 'CW', viewValue:   `Curaçao`},
      {value: 'CY', viewValue:   `Cypr`},
      // {value: 'TD', viewValue:   `Czad`},
      // {value: 'ME', viewValue:   `Czarnogóra`},
      {value: 'CZ', viewValue:   `Czechy`},
      // {value: 'UM', viewValue:   `Dalekie Wyspy Mniejsze Stanów Zjednoczonych`},
      {value: 'DK', viewValue:   `Dania`},
      // {value: 'CD', viewValue:   `Demokratyczna Republika Konga`},
      // {value: 'DM', viewValue:   `Dominika`},
      // {value: 'DO', viewValue:   `Dominikana`},
      // {value: 'DJ', viewValue:   `Dżibuti`},
      // {value: 'EG', viewValue:   `Egipt`},
      // {value: 'EC', viewValue:   `Ekwador`},
      // {value: 'ER', viewValue:   `Erytrea`},
      {value: 'EE', viewValue:   `Estonia`},
      // {value: 'SZ', viewValue:   `Eswatini`},
      // {value: 'ET', viewValue:   `Etiopia`},
      // {value: 'FK', viewValue:   `Falklandy`},
      // {value: 'FJ', viewValue:   `Fidżi`},
      // {value: 'PH', viewValue:   `Filipiny`},
      {value: 'FI', viewValue:   `Finlandia`},
      {value: 'FR', viewValue:   `Francja`},
      // {value: 'TF', viewValue:   `Francuskie Terytoria Południowe i Antarktyczne`},
      // {value: 'GA', viewValue:   `Gabon`},
      // {value: 'GM', viewValue:   `Gambia`},
      // {value: 'GS', viewValue:   `Georgia Południowa i Sandwich Południowy`},
      // {value: 'GH', viewValue:   `Ghana`},
      // {value: 'GI', viewValue:   `Gibraltar`},
      {value: 'GR', viewValue:   `Grecja`},
      // {value: 'GD', viewValue:   `Grenada`},
      // {value: 'GL', viewValue:   `Grenlandia`},
      // {value: 'GE', viewValue:   `Gruzja`},
      // {value: 'GU', viewValue:   `Guam`},
      // {value: 'GG', viewValue:   `Guernsey`},
      // {value: 'GF', viewValue:   `Gujana Francuska`},
      // {value: 'GY', viewValue:   `Gujana`},
      // {value: 'GP', viewValue:   `Gwadelupa`},
      // {value: 'GT', viewValue:   `Gwatemala`},
      // {value: 'GW', viewValue:   `Gwinea Bissau`},
      // {value: 'GQ', viewValue:   `Gwinea Równikowa`},
      // {value: 'GN', viewValue:   `Gwinea`},
      // {value: 'HT', viewValue:   `Haiti`},
      {value: 'ES', viewValue:   `Hiszpania`},
      {value: 'NL', viewValue:   `Holandia`},
      // {value: 'HN', viewValue:   `Honduras`},
      // {value: 'HK', viewValue:   `Hongkong`},
      // {value: 'IN', viewValue:   `Indie`},
      // {value: 'ID', viewValue:   `Indonezja`},
      // {value: 'IQ', viewValue:   `Irak`},
      // {value: 'IR', viewValue:   `Iran`},
      {value: 'IE', viewValue:   `Irlandia`},
      // {value: 'IS', viewValue:   `Islandia`},
      // {value: 'IL', viewValue:   `Izrael`},
      // {value: 'JM', viewValue:   `Jamajka`},
      // {value: 'JP', viewValue:   `Japonia`},
      // {value: 'YE', viewValue:   `Jemen`},
      // {value: 'JE', viewValue:   `Jersey`},
      // {value: 'JO', viewValue:   `Jordania`},
      // {value: 'KY', viewValue:   `Kajmany`},
      // {value: 'KH', viewValue:   `Kambodża`},
      // {value: 'CM', viewValue:   `Kamerun`},
      // {value: 'CA', viewValue:   `Kanada`},
      // {value: 'QA', viewValue:   `Katar`},
      // {value: 'KZ', viewValue:   `Kazachstan`},
      // {value: 'KE', viewValue:   `Kenia`},
      // {value: 'KG', viewValue:   `Kirgistan`},
      // {value: 'KI', viewValue:   `Kiribati`},
      // {value: 'CO', viewValue:   `Kolumbia`},
      // {value: 'KM', viewValue:   `Komory`},
      // {value: 'CG', viewValue:   `Kongo`},
      // {value: 'KR', viewValue:   `Korea Południowa`},
      // {value: 'KP', viewValue:   `Korea Północna`},
      // {value: 'CR', viewValue:   `Kostaryka`},
      // {value: 'CU', viewValue:   `Kuba`},
      // {value: 'KW', viewValue:   `Kuwejt`},
      // {value: 'LA', viewValue:   `Laos`},
      // {value: 'LS', viewValue:   `Lesotho`},
      // {value: 'LB', viewValue:   `Liban`},
      // {value: 'LR', viewValue:   `Liberia`},
      // {value: 'LY', viewValue:   `Libia`},
      // {value: 'LI', viewValue:   `Liechtenstein`},
      {value: 'LT', viewValue:   `Litwa`},
      // {value: 'LU', viewValue:   `Luksemburg`},
      {value: 'LV', viewValue:   `Łotwa`},
      // {value: 'MK', viewValue:   `Macedonia`},
      // {value: 'MG', viewValue:   `Madagaskar`},
      // {value: 'YT', viewValue:   `Majotta`},
      // {value: 'MO', viewValue:   `Makau`},
      // {value: 'MW', viewValue:   `Malawi`},
      // {value: 'MV', viewValue:   `Malediwy`},
      // {value: 'MY', viewValue:   `Malezja`},
      // {value: 'ML', viewValue:   `Mali`},
      // {value: 'MT', viewValue:   `Malta`},
      // {value: 'MP', viewValue:   `Mariany Północne`},
      // {value: 'MA', viewValue:   `Maroko`},
      // {value: 'MQ', viewValue:   `Martynika`},
      // {value: 'MR', viewValue:   `Mauretania`},
      // {value: 'MU', viewValue:   `Mauritius`},
      // {value: 'MX', viewValue:   `Meksyk`},
      // {value: 'FM', viewValue:   `Mikronezja`},
      // {value: 'MM', viewValue:   `Mjanma`},
      // {value: 'MD', viewValue:   `Mołdawia`},
      // {value: 'MC', viewValue:   `Monako`},
      // {value: 'MN', viewValue:   `Mongolia`},
      // {value: 'MS', viewValue:   `Montserrat`},
      // {value: 'MZ', viewValue:   `Mozambik`},
      // {value: 'NA', viewValue:   `Namibia`},
      // {value: 'NR', viewValue:   `Nauru`},
      // {value: 'NP', viewValue:   `Nepal`},
      {value: 'DE', viewValue:   `Niemcy`},
      // {value: 'NE', viewValue:   `Niger`},
      // {value: 'NG', viewValue:   `Nigeria`},
      // {value: 'NI', viewValue:   `Nikaragua`},
      // {value: 'NU', viewValue:   `Niue`},
      // {value: 'NF', viewValue:   `Norfolk`},
      {value: 'NO', viewValue:   `Norwegia`},
      // {value: 'NC', viewValue:   `Nowa Kaledonia`},
      // {value: 'NZ', viewValue:   `Nowa Zelandia`},
      // {value: 'OM', viewValue:   `Oman`},
      // {value: 'PK', viewValue:   `Pakistan`},
      // {value: 'PW', viewValue:   `Palau`},
      // {value: 'PS', viewValue:   `Palestyna`},
      // {value: 'PA', viewValue:   `Panama`},
      // {value: 'PG', viewValue:   `Papua-Nowa Gwinea`},
      // {value: 'PY', viewValue:   `Paragwaj`},
      // {value: 'PE', viewValue:   `Peru`},
      // {value: 'PN', viewValue:   `Pitcairn`},
      // {value: 'PF', viewValue:   `Polinezja Francuska`},
      {value: 'PL', viewValue:   `Polska`},
      // {value: 'PR', viewValue:   `Portoryko`},
      {value: 'PT', viewValue:   `Portugalia`},
      // {value: 'ZA', viewValue:   `Południowa Afryka`},
      // {value: 'CF', viewValue:   `Republika Środkowoafrykańska`},
      // {value: 'CV', viewValue:   `Republika Zielonego Przylądka`},
      // {value: 'RE', viewValue:   `Reunion`},
      {value: 'RU', viewValue:   `Rosja`},
      // {value: 'RO', viewValue:   `Rumunia`},
      // {value: 'RW', viewValue:   `Rwanda`},
      // {value: 'EH', viewValue:   `Sahara Zachodnia`},
      // {value: 'KN', viewValue:   `Saint Kitts i Nevis`},
      // {value: 'LC', viewValue:   `Saint Lucia`},
      // {value: 'VC', viewValue:   `Saint Vincent i Grenadyny`},
      // {value: 'BL', viewValue:   `Saint-Barthélemy`},
      // {value: 'MF', viewValue:   `Saint-Martin`},
      // {value: 'PM', viewValue:   `Saint-Pierre i Miquelon`},
      // {value: 'SV', viewValue:   `Salwador`},
      // {value: 'AS', viewValue:   `Samoa Amerykańskie`},
      // {value: 'WS', viewValue:   `Samoa`},
      // {value: 'SM', viewValue:   `San Marino`},
      // {value: 'SN', viewValue:   `Senegal`},
      // {value: 'RS', viewValue:   `Serbia`},
      // {value: 'SC', viewValue:   `Seszele`},
      // {value: 'SL', viewValue:   `Sierra Leone`},
      // {value: 'SG', viewValue:   `Singapur`},
      // {value: 'SX', viewValue:   `Sint Maarten`},
      {value: 'SK', viewValue:   `Słowacja`},
      {value: 'SI', viewValue:   `Słowenia`},
      // {value: 'SO', viewValue:   `Somalia`},
      // {value: 'LK', viewValue:   `Sri Lanka`},
      // {value: 'US', viewValue:   `Stany Zjednoczone`},
      // {value: 'SD', viewValue:   `Sudan`},
      // {value: 'SS', viewValue:   `Sudan Południowy`},
      // {value: 'SR', viewValue:   `Surinam`},
      // {value: 'SJ', viewValue:   `Svalbard i Jan Mayen`},
      // {value: 'SY', viewValue:   `Syria`},
      {value: 'CH', viewValue:   `Szwajcaria`},
      {value: 'SE', viewValue:   `Szwecja`},
      // {value: 'TJ', viewValue:   `Tadżykistan`},
      // {value: 'TH', viewValue:   `Tajlandia`},
      // {value: 'TW', viewValue:   `Tajwan`},
      // {value: 'TZ', viewValue:   `Tanzania`},
      // {value: 'TL', viewValue:   `Timor Wschodni`},
      // {value: 'TG', viewValue:   `Togo`},
      // {value: 'TK', viewValue:   `Tokelau`},
      // {value: 'TO', viewValue:   `Tonga`},
      // {value: 'TT', viewValue:   `Trynidad i Tobago`},
      // {value: 'TN', viewValue:   `Tunezja`},
      // {value: 'TR', viewValue:   `Turcja`},
      // {value: 'TM', viewValue:   `Turkmenistan`},
      // {value: 'TC', viewValue:   `Turks i Caicos`},
      // {value: 'TV', viewValue:   `Tuvalu`},
      // {value: 'UG', viewValue:   `Uganda`},
      {value: 'UA', viewValue:   `Ukraina`},
      // {value: 'UY', viewValue:   `Urugwaj`},
      // {value: 'UZ', viewValue:   `Uzbekistan`},
      // {value: 'VU', viewValue:   `Vanuatu`},
      // {value: 'WF', viewValue:   `Wallis i Futuna`},
      // {value: 'VA', viewValue:   `Watykan`},
      // {value: 'VE', viewValue:   `Wenezuela`},
      {value: 'HU', viewValue:   `Węgry`},
      {value: 'GB', viewValue:   `Wielka Brytania`},
      // {value: 'VN', viewValue:   `Wietnam`},
      {value: 'IT', viewValue:   `Włochy`},
      // {value: 'CI', viewValue:   `Wybrzeże Kości Słoniowej`},
      // {value: 'BV', viewValue:   `Wyspa Bouveta`},
      // {value: 'CX', viewValue:   `Wyspa Bożego Narodzenia`},
      // {value: 'IM', viewValue:   `Wyspa Man`},
      // {value: 'SH', viewValue:   `Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha`},
      // {value: 'AX', viewValue:   `Wyspy Alandzkie`},
      // {value: 'CK', viewValue:   `Wyspy Cooka`},
      // {value: 'VI', viewValue:   `Wyspy Dziewicze Stanów Zjednoczonych`},
      // {value: 'HM', viewValue:   `Wyspy Heard i McDonalda`},
      // {value: 'CC', viewValue:   `Wyspy Kokosowe`},
      // {value: 'MH', viewValue:   `Wyspy Marshalla`},
      // {value: 'FO', viewValue:   `Wyspy Owcze`},
      // {value: 'SB', viewValue:   `Wyspy Salomona`},
      // {value: 'ST', viewValue:   `Wyspy Świętego Tomasza i Książęca`},
      // {value: 'ZM', viewValue:   `Zambia`},
      // {value: 'ZW', viewValue:   `Zimbabwe`},
      // {value: 'AE', viewValue:   `Zjednoczone Emiraty Arabskie`}
    ]

};

