import { Injectable, Inject, PLATFORM_ID, DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Client } from './../../auth/auth.model';
import { environment } from './../../../environments/environment';
import { share } from 'rxjs/operators';

export class UserState {
  logged: boolean = false;
  scopes: string[] = [];
}

@Injectable()
export class AuthService {

  private loggedInSubject = new BehaviorSubject<UserState>(new UserState());
  // private loggedScopesSubject = new BehaviorSubject<string[]>([]);

  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(LOCALE_ID) private localeId: string, @Inject(DEFAULT_CURRENCY_CODE) private currency: string, private httpClient: HttpClient,
              private jwtHelperService: JwtHelperService) {

    this.jwtHelperService = new JwtHelperService({ tokenGetter: this.getToken });
    if (this.loggedIn()) {
      const token = localStorage.getItem('user_token');
      const decoded = this.jwtHelperService.decodeToken(token);
      this.loggedInSubject.next({logged: true, scopes: decoded.scopes});
      // this.loggedScopesSubject.next(decoded.scopes);
    }

  }

  get isLoggedIn(): Observable<UserState> {
    return this.loggedInSubject.asObservable().pipe(share());
  }

  // get scopes(): Observable<string[]> {
  //   return this.loggedScopesSubject.asObservable().pipe(share());
  // }

  getToken() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('user_token');
    } else {
      return false;
    }
  }

  setToken(token: string) {
    if (isPlatformBrowser(this.platformId)) {

      localStorage.removeItem('user_token');
      localStorage.setItem('user_token', token);

      const decoded = this.jwtHelperService.decodeToken(token);
      localStorage.setItem('user_id', decoded.sub);
      localStorage.setItem('user_role', decoded.scopes[0]);
      localStorage.setItem('user_scopes', decoded.scopes);
      const lastForCurrency = decoded.scopes[decoded.scopes.length - 1]; //last item = currency
      let currencyCode = 'PLN';
      if (environment.currencies.includes(lastForCurrency)) {
        currencyCode = lastForCurrency;
      }
      // this.currency = currencyCode;
      // localStorage.setItem('user_currency', currencyCode);
      // console.log('SETTING CURRENCY', currencyCode);
      if (decoded.scopes.includes('unconfirmed')){
        localStorage.setItem('user_state', 'unconfirmed');
      }
      this.loggedInSubject.next({logged: true, scopes: decoded.scopes});
      return true;
    } else {
      return false;
    }
  }

  loggedIn() {
    const token = this.getToken();
    if (token) {
      if (!this.jwtHelperService.isTokenExpired(token)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  login(username: String, password: String) {

    const credentials = 'grant_type=password'
                 + '&client_id=milionoczu'
                 + '&client_secret=Gg6pTzgXVFyWuwQg'
                 + '&client=true'
                 + '&username=' + username
                 + '&password=' + password;

    return this.httpClient.post<any>(environment.api + '/auth/login', credentials,
      {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')});

  }

  reset(email: String) {

    const data = {email: email};
    return this.httpClient.post<any>(environment.api + '/auth/reset', data);

  }

  register(client: Client) {
    return this.httpClient.post<Client>(environment.api + '/auth/register', client);
  }

  logout() {
    this.loggedInSubject.next({logged: false, scopes: []});
    return true;
  }


}
