<ng-container *ngIf="!edit">
<div *ngFor="let row of content" class="flx-dynamic flx-gap-double">
    <div *ngFor="let col of row" class="flx-col flx-dynamic-{{ (100/row.length).toFixed(0) }} flx-{{ col.valign ? col.valign : 'cntr' }}-strt" >
        <ng-container *ngFor="let block of col['blocks']">
        <ng-container [ngSwitch]="block['type']">
            <a *ngSwitchCase="'button'" class="flat-button primary text-{{ block['tunes']['alignment']['alignment'] }}" [routerLink]="block['data']['link']">{{ block['data']['text'] }}</a>
            <button *ngSwitchCase="'popup'" class="flat-button pink text-{{ block['tunes']['alignment']['alignment'] }}" (click)="emitClick('popup', block['data']['link'])">{{ block['data']['text'] }}</button>
            <picture class="oldimage" *ngSwitchCase="'image'"
                [class.withBorder]="block['data']['withBorder']"
                [class.withBackground]="block['data']['withBackground']"
                [class.stretched]="block['data']['stretched']">
                <img [alt]="'Classica'" [ngSrc]="block['data']['file']['url']" [loaderParams]="{}" width="720" height="720">
            </picture>
            <picture *ngSwitchCase="'gridimage'" class="ve-image-wrap"
            [class.withBorder]="block['data']['withBorder']"
            [class.withBackground]="block['data']['withBackground']"
            [class.stretched]="block['data']['stretched']">
                <img [alt]="block['data']['image'].title" [ngSrc]="block['data']['image'].filename" [loaderParams]="{module: this.module, dir: this.dir}" width="720" height="720">
            </picture>
            <div *ngSwitchCase="'gallery'" class="gallery-wrap">
                <picture class="ve-gallery-img-wrap" *ngFor="let image of block['data']['images']">
                    <img [alt]="image.title" [ngSrc]="image.filename" [loaderParams]="{module: this.module, dir: this.dir}" width="720" height="720"
                    gridzoom [url]="image.filename" [urls]="block['data']['images']" [dir]="module+'/'+dir">
                </picture>
            </div>
            <div class="products-wrap" *ngSwitchCase="'products'" (appDeferLoad)="fetchProducts(block['data']['products'])">
                <scrollable>
                    <div #scrollablewrap class="scrollable-wrap" *ngFor="let item of (products | async).items; let j = index;">
                        <app-product [product]="item"></app-product>
                    </div>
                </scrollable>
            </div>
            <div *ngSwitchDefault [outerHTML]="parseBlock(block)"></div>
         </ng-container>
         </ng-container>
    </div>
</div>
</ng-container>
<ng-container #editorvc></ng-container>

