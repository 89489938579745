export class Product {
  id: number;
  id_manufacturer: number;
  variant_id: number;
  title: string;
  title1: string;
  title2: string;
  series: string;
  path: string;
  path1: string;
  path2: string;
  photos_id: string;
  summary: string;
  content: string;
  photos_directory: string;
  width: number;
  height: number;
  length: number;
  width_product: number;
  height_product: number;
  length_product: number;
  diameter_product: number;
  dimension_units: number;
  weight: number;
  weight_units: number;
  new: number;
  day: number;
  configurable: number;
  price: number;
  price_promo: number;
  currency: number;
  delivery: string;
  availability: number;
  availability_txt: string;
  bestseller: number;
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
  status: number;
  extra_content_1: string;
  extra_content_2: string;
  extra_content_3: string;
  json_content_1: string;
  json_content_2: string;
  json_content_3: string;
  date_created: string;

  variants: ProductVariant[];
  categories: ProductCategory[];
  collections: Collection[];
  related: Product[];
  relation_type: string;
  photos: ProductPhoto[];
  manufacturer: Manufacturer;
  photo: string;
  tax: number;
  search_attributes: any[];
  category_attributes: any[];
  extra_1: string;
  extra_1_values: any[];
  extra_2: string;
  extra_2_values: any[];
  extra_3: string;
  extra_3_values: any[];
  extra_4: string;
  extra_4_values: any[];

  // front
  show: boolean;
  variant_selected: number;
  variants_string: string;
  price_history?: any;
}

export class ProductVariant {
  id: number;
  id_product: number;
  path: string;
  quantity: number;
  minimal_quantity: number;
  id_version: number;
  price_type: number;
  price: number;
  price_history?: number;
  price_attrs: number;
  price_promo: number;
  wholesale_price: number;
  currency: string;
  tax: number;
  location: string;
  sku: string;
  ean: string;
  isbn: string;
  upc: string;
  json_content_1: string;
  content: string;
  ord: number;
  photo_id: any;
  photo: string;
  attributes: ProductAttribute[];
  attribute_name: string;
  date_supply: string;
  status: number;

  //additional
  quantity_to_add: number;
}

export class ProductCategory {
  id_product: number;
  id_page: number;
  name: string;
  path: string;
}

export class Products {
  items: Product[];
  pages: any;
}

export class ProductPhoto {
  id: number;
  filename: string;
  model: number;
  extension: string;
  alt: string;
  title: string;
  description: string;
  ord: number;
}

export class ProductAttribute {
  id_product_variant: number;
  id_attribute_variant: number;
  title: string;
  title_safe: string;
  type: number;
  value_text: string;
  content: string;
  value_real: string;
  affects_photos: boolean;
  ord: number;
  group_ord: number;
  photos_directory: string;
  filename: string;
}

export class Manufacturer {
  id: number;
  photos_directory: string;
  name: string;
  path: string;
  content: string;
  photos: string;
}

export class Filter {
  id: number;
  title: string;
  title_safe: string;
  type: number;
  options: FilterOption[];
}

export class FilterOption {
  attr_id: number;
  value_text: string;
  value_real: string;
}

export class Collection {
  id: number;
  photos_directory: string;
  name: string;
  path: string;
  content: string;
  title: string;
  description: string;
  homepage: number;
  keywords: string;
  date_created: string;
  photos: any;
  products: any;
  total: number;
  min_price: number;
  loading: boolean;
}

export class Collections {
  items: Collection[];
  pages: any;
}

export const dimensionUnits = [
    {value: '1', viewValue: `cm`},
    {value: '2', viewValue: `mm`},
    {value: '3', viewValue: `m`},
];


export const productDeliveries = [
 {value: '1', viewValue: '24h'},
 {value: '2', viewValue: `24h po dostawie towaru`},
 {value: '3', viewValue: '48h'},
 {value: '4', viewValue: `48h po dostawie towaru`},
 {value: '5', viewValue: '72h'},
 {value: '6', viewValue: `72h po dostawie towaru`},
];

export const productAvailabilities = [
 {value: '1', viewValue: `Dostępny`},
 {value: '2', viewValue: `Na zamówienie`},
 {value: '3', viewValue: `Mało na magazynie`},
 {value: '4', viewValue: `Brak na magazynie`},
 {value: '5', viewValue: '-'},
];

