import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, Input, HostBinding, Inject, OnDestroy, Output, EventEmitter, NgZone, ViewChildren, QueryList, AfterContentInit, ContentChildren, AfterViewInit } from '@angular/core';
import { SharedModule } from '../shared.module';
import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, EMPTY, Observable, Subscription, fromEvent, merge, takeUntil, timer } from 'rxjs';
import { map, concatMap, first, elementAt, catchError, filter } from 'rxjs/operators';

@Component({
    standalone: true,
    selector: 'scrollable',
    imports: [CommonModule, NgOptimizedImage],
    templateUrl: 'scrollable.component.html',
    styleUrls: ['scrollable.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollableComponent implements AfterViewInit, AfterContentInit, OnDestroy {

    @Input('type') type = 'multi';
    @Output() public change: EventEmitter<any> = new EventEmitter();
    @Input() @HostBinding('class.withscrollbar') withscrollbar: boolean = true;
    @ViewChild('scrollable', { static: false }) scrollable: ElementRef;
    @ViewChild('scrollbar', { static: false, read: ElementRef }) set aRef(ref: ElementRef) { this.scrollbar = ref.nativeElement; }
    @ViewChildren('button') buttons: QueryList<ElementRef>;

    @ContentChildren('scrollablewrap') children: QueryList<ElementRef>;

    @HostBinding('class.inside') @Input() inside: boolean = false;
    @HostBinding('class.gallery') @Input() gallery: any = null;

    public cdn = environment.cdn;
    public hidden = true;
    private inited = false;
    private total = 0;
    private barWidth = 0;
    private elementWidth = 0;
    private scale = 0;
    // private currentX = 0;
    public limitStart:BehaviorSubject<number> = new BehaviorSubject(0);
    public limitEnd:BehaviorSubject<number> = new BehaviorSubject(0);
    public currentX:BehaviorSubject<number> = new BehaviorSubject(0);
    private subscriptions: Subscription[] = [];
    private scrollSub:Subscription;
    private scrollbar: HTMLElement;
    private timeout: any;

    constructor(@Inject(DOCUMENT) private document: any, private ngZone: NgZone, private elRef:ElementRef) { }

    ngAfterViewInit(): void {        
        this.reInitDrag();
        this.children.changes.subscribe(() => {
            this.reInitDrag();
        });
    }

    ngAfterContentInit(): void {
       
    }

    reInitDrag() {
        
        this.total = this.children.length;
        this.limitStart.next(this.scrollable.nativeElement.clientWidth - 8);
        console.log(this.children.length-1);
        this.limitEnd.next((this.scrollable.nativeElement.clientWidth * (this.children.length-2)) + 8);
        this.subscriptions.forEach((s) => s?.unsubscribe());
        this.scrollSub = null;
        this.initDrag();
    }

    public scrollLeft() {
        if (this.scrollable) {
            const newX = this.scrollable.nativeElement.scrollLeft - Math.floor(this.scrollable.nativeElement.clientWidth);
            this.scrollable.nativeElement.scrollTo({
                left: newX,
                behavior: 'smooth'
            });
            this.currentX.next(newX);
        }
    }

    public scrollRight() {
        if (this.scrollable) {
            const newX = this.scrollable.nativeElement.scrollLeft + Math.floor(this.scrollable.nativeElement.clientWidth);
            this.scrollable.nativeElement.scrollTo({
                left: newX,
                behavior: 'smooth'
            });
            this.currentX.next(newX);
        }
    }

    public scrollTo(i: number, behavior = 'smooth') {
        if (this.scrollable) {
            const newX = (i * Math.floor(this.scrollable.nativeElement.firstChild.clientWidth));
            this.scrollable.nativeElement.scrollTo({
                left: newX,
                behavior: behavior
            });
            this.currentX.next(newX);
        }
    }

    initDrag(): void {
        this.ngZone.runOutsideAngular(() => {

            this.inited = true;

            this.barWidth = this.scrollbar.parentElement.clientWidth;
            this.elementWidth = 0;
            for (let item of this.children) {
                this.elementWidth += item.nativeElement.clientWidth;
            }
            this.scale = this.elementWidth / this.barWidth;
            // console.log( this.scrollable.nativeElement.firstChild.clientWidth, this.scrollable.nativeElement.children, this.elementWidth, this.scrollable.nativeElement.querySelectorAll('.scrollable-wrap'));

            // console.log(this.visible, this.scale, this.elementWidth, this.barWidth, this.scrollable.nativeElement.firstChild.clientWidth);

            if (this.barWidth > this.elementWidth) {
                this.scrollbar.parentElement.style.display = "none";
                // this.buttons.forEach(b => b.nativeElement.style.display = 'none');
            } else {

                let percent =  this.barWidth/this.elementWidth;
                this.scrollbar.style.width = this.barWidth * percent + 'px';
                // this.buttons.forEach(b => b.nativeElement.style.display = 'block');
                let limitX = this.elementWidth - this.scrollable.nativeElement.clientWidth;
                let initialX;

                this.scrollSubscription();
                const observables = this.getDragObservables(this.elRef.nativeElement);

                observables.clicks.forEach(() => {
                    console.log('CLICK :)');
                });

                observables.holds.forEach(coordinate => {
                    this.scrollable.nativeElement.style.scrollSnapType = 'none';
                    this.scrollable.nativeElement.style.cursor = 'grab';
                    initialX = this.scrollable.nativeElement.scrollLeft;
                });
        
                observables.dragMoves.forEach(coordinate => {
                    // console.log(coordinate.x, initialX);
                    // let x = Math.max(0, Math.min(coordinate.x, limitX));
                    if (coordinate.x >= -limitX && coordinate.x <= limitX) {
                        this.scrollable.nativeElement.scrollLeft = initialX - coordinate.x;
                    } 
                });
        
                observables.dragMoveEnds.forEach(coordinate => {
                    this.scrollable.nativeElement.style.scrollSnapType = 'x mandatory';
                    this.scrollable.nativeElement.style.cursor = 'default';
                    initialX = this.currentX.value;
                    this.currentX.next(initialX);
                });
            }
        });

  
    }

    scrollSubscription() {
        if (this.scrollSub == null) {
            const scroll$ = fromEvent<any>(this.scrollable.nativeElement, "scroll");
            this.scrollSub = scroll$.subscribe((event: any) => {
                this.currentX.next(event.target.scrollLeft);
                this.scrollbar.style.transform = "translate3d(" + (event.target.scrollLeft / this.scale) + "px, 0, 0)";
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.ngZone.run(() => this.currentX.next(event.target.scrollLeft));
                    console.log('end scroll');
                }, 50);
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s?.unsubscribe());
    }


    getDragObservables(domItem) {
        //https://codepen.io/HunorMarton/pen/qmJvvY
        const preventDefault = event => {
            if(event.target.tagName !== "A" && event.target.tagName !== "BUTTON") event.preventDefault();
        }
        const mouseEventToCoordinate = mouseEvent => {
            preventDefault(mouseEvent);
            return {x: mouseEvent.clientX, y: mouseEvent.clientY};
        };
        // const touchEventToCoordinate = touchEvent => {
        // preventDefault(touchEvent);
        // return {x: touchEvent.changedTouches[0].clientX, y: touchEvent.changedTouches[0].clientY};
        // };
    
        let mouseDowns = fromEvent(domItem, "mousedown").pipe(map(mouseEventToCoordinate));
        let mouseMoves = fromEvent(window, "mousemove").pipe(map(mouseEventToCoordinate));
        let mouseUps = fromEvent(window, "mouseup").pipe(map(mouseEventToCoordinate));
    
        // let touchStarts = fromEvent(domItem, "touchstart").pipe(map(touchEventToCoordinate));
        // let touchMoves = fromEvent(domItem, "touchmove").pipe(map(touchEventToCoordinate));
        // let touchEnds = fromEvent(window, "touchend").pipe(map(touchEventToCoordinate));
        // let touchCancels = fromEvent(window, "touchcancel").pipe(map(touchEventToCoordinate));
    
        // let _starts = merge(mouseDowns, touchStarts);
        // let _moves = merge(mouseMoves, touchMoves);
        // let _ends = merge(mouseUps, touchEnds, touchCancels);
        let _starts = merge(mouseDowns);
        let _moves = merge(mouseMoves);
        let _ends = merge(mouseUps);
        
        const HOLDING_PERIOD = 50; // milliseconds
    
        let clicks = _starts.pipe(concatMap(dragStartEvent =>  
        _ends.pipe(first(),                       
            takeUntil(_moves.pipe(elementAt(3))),
            takeUntil(timer(HOLDING_PERIOD)),
            catchError(err => EMPTY)
        )));
        
        let holds = _starts.pipe(concatMap(dragStartEvent => 
            timer(HOLDING_PERIOD).pipe(
                takeUntil(_moves.pipe(elementAt(3))),             
                takeUntil(_ends),
                map(() => ({x: dragStartEvent.x, y: dragStartEvent.y})),
                catchError(err => EMPTY)
            )
        ));
    
        let moveStartsWithDirection = _starts.pipe(concatMap(dragStartEvent => 
        _moves.pipe(
            takeUntil(_ends),
            takeUntil(timer(HOLDING_PERIOD)),  
            elementAt(3),
            catchError(err => EMPTY),
            map(dragEvent => {
                const intialDeltaX = dragEvent.x - dragStartEvent.x;
                const initialDeltaY = dragEvent.y - dragStartEvent.y;
                return {x: dragStartEvent.x, y: dragStartEvent.y, intialDeltaX, initialDeltaY};
            })
        )));
    
        // Vertical move starts: Keep only those move start events where the 3rd subsequent move event is rather vertical than horizontal
        let verticalMoveStarts = moveStartsWithDirection.pipe(filter(dragStartEvent => 
        Math.abs(dragStartEvent.intialDeltaX) < Math.abs(dragStartEvent.initialDeltaY)
        ));//.subscribe(() => console.log('vertical move starts'));
    
        // Horizontal move starts: Keep only those move start events where the 3rd subsequent move event is rather horizontal than vertical
        let horizontalMoveStarts = moveStartsWithDirection.pipe(filter(dragStartEvent => 
        Math.abs(dragStartEvent.intialDeltaX) >= Math.abs(dragStartEvent.initialDeltaY)
        ));//.subscribe(() => console.log('horizontal move starts'));
    
        // Take the moves until an end occurs
        const movesUntilEnds = dragStartEvent => 
        _moves.pipe(takeUntil(_ends), map(dragEvent => {
            const x = dragEvent.x - dragStartEvent.x;
            const y = dragEvent.y - dragStartEvent.y;
            return {x, y};
        }));
    
        let verticalMoves = verticalMoveStarts.pipe(concatMap(movesUntilEnds));//.do(() => console.log('vertical move'));
        let horizontalMoves = horizontalMoveStarts.pipe(concatMap(movesUntilEnds));//.do(() => console.log('horizontal move'));
        let dragMoves = holds.pipe(concatMap(movesUntilEnds));//.do(() => console.log('dragging'));
    
        const lastMovesAtEnds = dragStartEvent => 
        _ends.pipe(first(), map(dragEndEvent => {
            const x = dragEndEvent.x - dragStartEvent.x;
            const y = dragEndEvent.y - dragStartEvent.y;
            return {x, y};
        }));
    
        let ends = _starts.pipe(concatMap(lastMovesAtEnds));
        let verticalMoveEnds = verticalMoveStarts.pipe(concatMap(lastMovesAtEnds));//.do(() => console.log('vertical move end'));
        let horizontalMoveEnds = horizontalMoveStarts.pipe(concatMap(lastMovesAtEnds))//.do(() => console.log('horizontal move end'));
        let dragMoveEnds = holds.pipe(concatMap(lastMovesAtEnds))//.do(() => console.log('dragging end'));
    
        return {
            clicks, 
            holds,
            verticalMoveStarts, horizontalMoveStarts, 
            verticalMoves, horizontalMoves, 
            verticalMoveEnds, horizontalMoveEnds,
            dragMoves, dragMoveEnds
        };
    }

}