import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotfoundComponent } from './errors/notfound.component';
import { environment } from './../environments/environment';
import { AuthGuard } from './shared/auth/auth-guard.service';

@NgModule({
  imports: [
    RouterModule.forRoot([
    {
        path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), data: {
            id: -979,
            name: '',
            title: `Strona główna`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base,
            hideBredcrumbs: true
        }
    },
    {
        path: 'promocje-pierscionki', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), data: {
            id: -978,
            name: '',
            title: `Strona główna`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/promocje-pierscionki',
            hideBredcrumbs: true
        }
    },
    {
        path: 'promocje-obraczki', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), data: {
            id: -977,
            name: '',
            title: `Strona główna`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/promocje-obraczki',
            hideBredcrumbs: true
        }
    },
    { path: `zaloguj`, loadChildren: () => import('./auth/login.module').then(m => m.LoginModule), data: {
            id: -980,
            name: `Zaloguj`,
            title: `Zaloguj`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `zaloguj`,
            hideDiscountPopup: true
        } },
    { path: `przypomnij-haslo`, loadChildren: () => import('./auth/password.module').then(m => m.PasswordModule), data: {
            id: -981,
            name: `Przypominj hasło`,
            title: `Przypominj hasło`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `przypomnij-haslo`,
            hideDiscountPopup: true
        } },
    { path: `rejestracja`, loadChildren: () => import('./auth/register.module').then(m => m.RegisterModule), data: {
            id: -982,
            name: `Rejestracja`,
            title: `Rejestracja`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `rejestracja`,
            hideDiscountPopup: true
        } },
    { path: `rejestracja` + '/:type', loadChildren: () => import('./auth/register.module').then(m => m.RegisterModule), data: {
            id: -983,
            name: `Rejestracja`,
            title: `Rejestracja`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `rejestracja` + '/:type',
            hideDiscountPopup: true
        } },
    { path: `wyloguj`, loadChildren: () => import('./auth/logout.module').then(m => m.LogoutModule), data: {
            id: -984,
            name: `Wyloguj`,
            title: `Wyloguj`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `wyloguj`
        } },
    { path: `konto`, loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard], data: {
            id: -991,
            name: `Konto`,
            title: `Konto`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `konto`,
            hideDiscountPopup: true
        } },
    { path: `konto/profil`, loadChildren: () => import('./user/user-details.module').then(m => m.UserDetailsModule), canActivate: [AuthGuard], data: {
            id: -992,
            name: `Dane adresowe`,
            title: `Dane adresowe`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `konto/profil`,
            hideDiscountPopup: true
        } },
    {path: `konto/haslo`, loadChildren: () => import('./user/user-password.module').then(m => m.UserPasswordModule), canActivate: [AuthGuard], data: {
            id: -1007,
            name: `Zmiana hasła`,
            title: `Zmiana hasła`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base +'/'+ `konto/haslo`
    }},
    { path: `konto/zamowienia`, loadChildren: () => import('./user/user-orders.module').then(m => m.UserOrdersModule), canActivate: [AuthGuard], data: {
            id: -993,
            name: `Zamówienia`,
            title: `Zamówienia`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `konto/zamowienia`,
            hideDiscountPopup: true
        } },
    { path: `konto/faktury`, loadChildren: () => import('./user/user-documents.module').then(m => m.UserDocumentsModule), canActivate: [AuthGuard], data: {
            id: -1002,
            name: `Faktury`,
            title: `Faktury`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `konto/faktury`,
            hideDiscountPopup: true
        } },
    { path: `konto/pliki`, loadChildren: () => import('./user/user-files.module').then(m => m.UserFilesModule), canActivate: [AuthGuard], data: {
            id: -1004,
            name: `Pliki`,
            title: `Pliki`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `konto/pliki`,
            hideDiscountPopup: true
        } },
    { path: `konto/zwroty`, loadChildren: () => import('./user/user-returns.module').then(m => m.UserReturnsModule), canActivate: [AuthGuard], data: {
            id: -994,
            name: `Reklamacje`,
            title: `Reklamacje`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `konto/zwroty`,
            hideDiscountPopup: true
        } },
    { path: `konto/rabaty`, loadChildren: () => import('./user/user-discounts.module').then(m => m.UserDiscountsModule), canActivate: [AuthGuard], data: {
            id: -995,
            name: `Rabaty`,
            title: `Rabaty`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `konto/rabaty`
        } },
    { path: `schowek`, loadChildren: () => import('./user/user-favorites.module').then(m => m.UserFavoritesModule), data: {
            id: -1005,
            name: `Schowek`,
            title: `Schowek`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `schowek`
        } },
    { path: `kasa`, loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule), data: {
            id: -997,
            name: `Kasa`,
            title: `Kasa`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `kasa`,
            hideDiscountPopup: true
        } },
    { path: 'payu/:token', loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule), data: {
            id: -998,
            name: `Płatność`,
            title: `Płatność`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/' + `platnosc`,
            hideDiscountPopup: true
        } },
    { path: `dziekujemy` + '/:id', loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule), data: {
            id: -999,
            name: `Dziękujemy za płatność`,
            title: `Dziękujemy za płatność`,
            content: '',
            description: '',
            keywords: '',
            hideDiscountPopup: true
        } },
    { path: `promocje` + '/:path', loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule), data: {
            id: -1000,
            name: `Produkty w promocji`,
            title: `Produkty w promocji`,
            content: '',
            description: '',
            keywords: 'produkty w promocji',
            canonical: environment.base + `promocje`
        } },
    { path: 'order/:path/:token', loadChildren: () => import('./confirmations/confirmations.module').then(m => m.ConfirmationsModule), data: {
            id: -1001,
            name: `Potwierdzenie zamówienia`,
            title: `Potwierdzenie zamówienia`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/order',
            hideBredcrumbs: true,
            hideDiscountPopup: true
        } },
    { path: 'reset/:path/:token', loadChildren: () => import('./confirmations/confirmations.module').then(m => m.ConfirmationsModule), data: {
            id: -1002,
            name: `Resetowanie hasła`,
            title: `Resetowanie hasła`,
            content: '',
            description: '',
            keywords: '',
            canonical: environment.base + '/reset',
            hideBredcrumbs: true,
            hideDiscountPopup: true
        } },
    { path: 'konfiguracja/:id', loadChildren: () => import('./engine/engine.module').then(m => m.EngineModule), data: { name: 'Konfigurator', title: 'Konfigurator Classica - Moje wymarzone obrączki ślubne', content: '', description: 'Zobacz moje wymarzone obrączki zaprojektowane w konfiguratorze Classica :)', keywords: 'konfigurator obrączek ślubnych', hideBredcrumbs: true, hideChat: true, hideDiscountPopup: true } },
    { path: 'konfigurator/obraczka/:premade', loadChildren: () => import('./engine/engine.module').then(m => m.EngineModule), data: { name: 'Konfigurator', title: 'Konfigurator Classica - Moje wymarzone obrączki ślubne', content: '', description: 'Zobacz moje wymarzone obrączki zaprojektowane w konfiguratorze Classica :)', keywords: 'konfigurator obrączek ślubnych', hideBredcrumbs: true, hideChat: true, hideDiscountPopup: true } },
    { path: 'konfigurator/obraczka/:premade/:id', loadChildren: () => import('./engine/engine.module').then(m => m.EngineModule), data: { name: 'Konfigurator', title: 'Konfigurator Classica - Moje wymarzone obrączki ślubne', content: '', description: 'Zobacz moje wymarzone obrączki zaprojektowane w konfiguratorze Classica :)', keywords: 'konfigurator obrączek ślubnych', hideBredcrumbs: true, hideChat: true, hideDiscountPopup: true } },
    { path: 'konfigurator/obraczka/:premade/:id/:alloy', loadChildren: () => import('./engine/engine.module').then(m => m.EngineModule), data: { name: 'Konfigurator', title: 'Konfigurator Classica - Moje wymarzone obrączki ślubne', content: '', description: 'Zobacz moje wymarzone obrączki zaprojektowane w konfiguratorze Classica :)', keywords: 'konfigurator obrączek ślubnych', hideBredcrumbs: true, hideChat: true, hideDiscountPopup: true } },
    { path: 'konfigurator/:color/:size/:height/:profile', loadChildren: () => import('./engine/engine.module').then(m => m.EngineModule), data: { name: 'Konfigurator', title: 'Konfigurator Classica - Moje wymarzone obrączki ślubne', content: '', description: 'Zobacz moje wymarzone obrączki zaprojektowane w konfiguratorze Classica :)', keywords: 'konfigurator obrączek ślubnych', hideBredcrumbs: true, hideChat: true, hideDiscountPopup: true } },
    { path: 'konfigurator/:color/:size/:height', loadChildren: () => import('./engine/engine.module').then(m => m.EngineModule), data: { name: 'Konfigurator', title: 'Konfigurator Classica - Moje wymarzone obrączki ślubne', content: '', description: 'Zobacz moje wymarzone obrączki zaprojektowane w konfiguratorze Classica :)', keywords: 'konfigurator obrączek ślubnych', hideBredcrumbs: true, hideChat: true, hideDiscountPopup: true } },
    { path: 'konfigurator/:color/:size', loadChildren: () => import('./engine/engine.module').then(m => m.EngineModule), data: { name: 'Konfigurator', title: 'Konfigurator Classica - Moje wymarzone obrączki ślubne', content: '', description: 'Zobacz moje wymarzone obrączki zaprojektowane w konfiguratorze Classica :)', keywords: 'konfigurator obrączek ślubnych', hideBredcrumbs: true, hideChat: true, hideDiscountPopup: true } },
    { path: 'konfigurator', loadChildren: () => import('./engine/engine.module').then(m => m.EngineModule), data: { name: 'Konfigurator', title: 'Konfigurator Classica - Moje wymarzone obrączki ślubne', content: '', description: 'Zobacz moje wymarzone obrączki zaprojektowane w konfiguratorze Classica :)', keywords: 'konfigurator obrączek ślubnych', hideBredcrumbs: true, hideChat: true, hideDiscountPopup: true  } },
    // { path: 'jubiler-:city', loadChildren: () => import('./page/page.module').then(m => m.PageModule), data: { name: 'Jubiler', title: 'Jubiler', content: '', description: '', keywords: '', hideBredcrumbs: true, hideChat: true, hideDiscountPopup: true } },
    { path: 'jubiler-:city', loadComponent: () => import('./page/lazy4.component').then(m => m.Lazy4Component), data: { name: 'Jubiler', title: 'Jubiler', content: '', description: '', keywords: '', hideBredcrumbs: true, hideChat: true, hideDiscountPopup: true } },
  
    { path: 'OfferModule', loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'OfferDetailsModule', loadChildren: () => import('./offer/offer-details.module').then(m => m.OfferDetailsModule), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'ArticleModule', loadChildren: () => import('./article/article.module').then(m => m.ArticleModule), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'ArticleDetailsModule', loadChildren: () => import('./article/article-details.module').then(m => m.ArticleDetailsModule), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'ContactModule', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'ReservationsModule', loadChildren: () => import('./reservations/reservations.module').then(m => m.ReservationsModule), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'DistributorsModule', loadChildren: () => import('./distributors/distributors.module').then(m => m.DistributorsModule), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'SimpleformsModule', loadChildren: () => import('./simpleforms/simpleforms.module').then(m => m.SimpleformsModule), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'SpecialsModule', loadChildren: () => import('./specials/specials.module').then(m => m.SpecialsModule), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'CatalogsModule', loadChildren: () => import('./catalogs/catalogs.module').then(m => m.CatalogsModule), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'CollectionsModule', loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'PageModule', loadComponent: () => import('./page/simple.component').then(m => m.SimpleComponent), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'lazy1.component', loadComponent: () => import('./page/lazy1.component').then(m => m.Lazy1Component), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'lazy2.component', loadComponent: () => import('./page/lazy2.component').then(m => m.Lazy2Component), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'lazy3.component', loadComponent: () => import('./page/lazy3.component').then(m => m.Lazy3Component), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'lazy4.component', loadComponent: () => import('./page/lazy4.component').then(m => m.Lazy4Component), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'lazy5.component', loadComponent: () => import('./page/lazy5.component').then(m => m.Lazy5Component), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'lazy6.component', loadComponent: () => import('./page/lazy6.component').then(m => m.Lazy6Component), data: { name: '', title: '', content: '', description: '', keywords: ''} },
    { path: 'lazy-diamonds.component', loadComponent: () => import('./page/lazy-diamonds.component').then(m => m.LazyDiamondsComponent), data: { name: '', title: '', content: '', description: '', keywords: ''} },

    { path: '404', component: NotfoundComponent, data: { id: -905, title: '404', content: '', description: '', keywords: '' } },
    { path: '**', redirectTo: '/404', data: { id: -1, title: '', content: '', description: '', keywords: '' } }
], { initialNavigation: 'enabledNonBlocking', scrollPositionRestoration: 'enabled', enableTracing: false, anchorScrolling: 'enabled'})
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }


