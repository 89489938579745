
<div class="gutter hidex flx-col flx-gap-quadruple bggrey">
    <div class="flx-col flx-cntr-cntr"> 
    <h1>Whoops!</h1>
    <p><b >Przepraszamy, ale strona której szukasz nie istnieje.</b></p>
    <p >Znaleźliśmy dla Ciebie dodatkowo kilka wyróżnionych produktów, które mogą Cię zainteresować.</p>
    </div>

</div>
<div class="gutter gutter-b hidex flx-col flx-gap-quadruple">
    <div class="flx-col flx-gap-default">
        <div class="fancy-box flx-dynamic flx-gap-default flx-strt-strt flx-btwn-end-md" (appDeferLoad)="fetchFeatured()">
            <div class="flx-col">
                <h4 >{{ sitename }}</h4>
                <h2 >WYRÓŻNIONE PRODUKTY</h2>
            </div>
            <a class="link" [routerLink]="['/'+path_offer]" >Zobacz wszystkie</a>
        </div>

        <scrollable>
            <div #scrollablewrap class="scrollable-wrap" *ngFor="let item of products; let j = index;">
                <app-product [product]="item"></app-product>
            </div>
        </scrollable>
    </div>
</div>

<div class="gutter gutter-b hidex flx-col flx-gap-quadruple">
    <div class="flx-col flx-cntr-cntr"> 
    <h3 >Nie widzisz nic interesującego ?</h3>
    <p >Skorzystaj z naszej wyszukiwarki na górze strony, zobacz pełną ofertę lub skontaktuj się nami.</p>
    <div class="buttons gutter-v-half flx-dynamic flx-gap-default flx-cntr-cntr"> 
        <a class="flat-button" [routerLink]="['/']" >strona główna</a>
        <a *ngIf="path_offer != ''" class="flat-button" [routerLink]="['/'+path_offer]" >nasza oferta</a>
        <a *ngIf="path_contact != ''" class="flat-button" [routerLink]="['/'+path_contact]" >kontakt</a>
        </div>
    </div>
</div>
